<template>
  <FormComponent :dialog="dialog" :config="config" :valid="valid" :extraValid="allItemsHaveFactors" :props-dialog="{ maxWidth: '1024px' }"
    @save:form="saveConfig" @update:dialog="dialog = $event" @update:valid="valid = $event"
    @load:init-data="handleInitData()" @clear:form="clear($event)">
    <template v-slot:custom-fields>
      <LoadingWrapper :loading="loadingFields">
        <v-row>
          <v-col cols="6">
            <v-autocomplete :items="process" :no-data-text="$t('common.fields.noDataAvailable')" :clearable="true"
              v-model="processId" item-color="primary" item-text="proccess_name" item-value="id" dense flat outlined
              color="primary" :loading="loading.process" @change="getGroups()"
              :menu-props="{ closeOnContentClick: false }">
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.process") }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete :items="groups" :no-data-text="$t('common.fields.noDataAvailable')" :clearable="true"
              :label="$t('widgets.dialogs.filters.group')" v-model="groupId" item-color="primary" item-text="name"
              item-value="id" dense flat outlined color="primary" :disabled="loading.process && groups.length === 0"
              @change="getStructuresAndValues()">
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.group") }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete :items="listStructures" :no-data-text="$t('common.fields.noDataAvailable')"
              :clearable="true" v-model="listStructureId" item-color="primary" 
              item-text="value" item-value="id" dense flat outlined color="primary"
              :disabled="loading.process || !groupId" @change="loadNestedValues">
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.listStructure") || "List Structure" }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete :items="nestedValues" :no-data-text="$t('common.fields.noDataAvailable')"
              :clearable="true" v-model="nestedValue" item-color="primary" 
              item-text="value" item-value="value" dense flat outlined color="primary"
              :disabled="loading.process || !listStructureId || loading.nestedValues">
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.nestedValue") || "Nested Value" }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete :items="numberStructures" :no-data-text="$t('common.fields.noDataAvailable')"
              :clearable="true" v-model="numberStructureId" item-color="primary" 
              item-text="value" item-value="id" dense flat outlined color="primary"
              :disabled="loading.process || !groupId">
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.numberStructure") || "Number Structure" }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn text class="px-3" depressed small @click="addToTable" :disabled="!isValid">
              <v-icon left>mdi-plus</v-icon>
              {{ $t('common.buttons.add') }}
            </v-btn>
          </v-col>
        </v-row>

        <!-- Simple inline notification for duplicates instead of CustomSnackbar -->
        <v-fade-transition>
          <div v-if="showDuplicateAlert" class="duplicate-alert">
            <div class="alert-content">
              <v-icon class="mr-2" small>mdi-alert</v-icon>
              <span>{{ $t('widgets.dialogs.alerts.duplicateStructure') }}</span>
              <v-btn icon x-small class="ml-auto" @click="showDuplicateAlert = false">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-fade-transition>

        <!-- Improved table design -->
        <v-card class="mt-4 elevation-0" outlined>
          <v-card-title class="py-2 d-flex align-center">
            <span class="text-subtitle-1">{{ $t("widgets.dialogs.tableTitle") }}</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="tableSearch"
              append-icon="mdi-magnify"
              :label="$t('common.titles.filter')"
              single-line
              hide-details
              dense
              outlined
              elevation="0"
              class="ml-3"
            ></v-text-field>
          </v-card-title>

          <v-data-table 
            :headers="tableHeaders" 
            :items="tableData" 
            item-value="id" 
            dense 
            class="factor-table"
            :items-per-page="5"
            :search="tableSearch"
            :custom-filter="customFilter"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 20],
              'items-per-page-text': $t('common.table.itemsPerPageText')
            }"
            @page-count="pageCount = $event"
            @current-items="currentItems = $event"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="index % 2 === 0 ? 'grey lighten-4' : ''">
                <td class="px-4 py-2">{{ translate(item.processName) }}</td>
                <td class="px-4 py-2">{{ translate(item.groupName) }}</td>
                <td class="px-4 py-2">{{ translate(item.listStructureName) }}</td>
                <td class="px-4 py-2">{{ item.nestedValue }}</td>
                <td class="px-4 py-2">{{ translate(item.numberStructureName) }}</td>
                <td class="px-4 py-2" style="width: 150px">
                  <v-text-field v-model="item.factor" dense outlined hide-details type="number" class="factor-input"
                    :rules="[v => !!v || $t('common.rules.required')]" @input="validateAllFactors" />
                </td>
                <td class="text-center">
                  <v-btn icon small color="error" @click="removeFromTable(index)" class="mr-1">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <div class="pa-4 text-center grey--text">
                {{ $t('common.ui.not_data_found') }}
              </div>
            </template>
            <template v-slot:footer>
              <!-- Missing factor alert -->
              <v-alert
                v-if="!allItemsHaveFactors && tableData.length > 0"
                text
                outlined
                color="deep-orange"
                icon="mdi-alert"
                style="box-shadow: none !important;"
                class="my-2"
              >
                {{ $t('widgets.dialogs.alerts.missingFactors') }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </LoadingWrapper>
    </template>
  </FormComponent>
</template>

<script>
import { mapGetters } from "vuex";
import FormComponent from "@/module/dashboard/components/common/FormComponent.vue";
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import { ProcessStructureLoaderMixin } from "@dashboard/mixins/process-structure-loader.mixin";
import i18n from "@/plugins/i18n";
import ReadMore from "@/components/ReadMoreComponent.vue";
import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue";
import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";

export default {
  name: 'FactorDialog',
  components: { 
    AFooterPaginatorField, 
    LoadingWrapper, 
    ReadMore, 
    FormComponent, 
    IconPicker, 
    ColorPicker
  },
  mixins: [FormWidgetMixin, ProcessStructureLoaderMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.CALC_WITH_FACTOR'),
        icon: 'mdi-card-bulleted',
        color: '#232323',
        filter: {
          processId: null,
          groupId: null,
          structureId: null,
          params: []
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'CALC_WITH_FACTOR',
        type: 'CARD'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.CALC_WITH_FACTOR'),
        icon: 'mdi-card-bulleted',
        color: '#232323',
        filter: {
          params: []
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'CALC_WITH_FACTOR',
        type: 'CARD'
      },
      defaultConfig: null,
      loadingFields: false,
      tableData: [],
      tableHeaders: [
        { text: this.$t("widgets.dialogs.headers.process"), value: "processName" },
        { text: this.$t("widgets.dialogs.headers.group"), value: "groupName" },
        { text: this.$t("widgets.dialogs.headers.listStructure"), value: "listStructureName" },
        { text: this.$t("widgets.dialogs.headers.nestedValue"), value: "nestedValue" },
        { text: this.$t("widgets.dialogs.headers.numberStructure"), value: "numberStructureName" },
        { text: this.$t("widgets.dialogs.headers.factor"), value: "factor" },
        { text: this.$t("widgets.dialogs.headers.actions"), value: "actions", sortable: false }
      ],
      dataToCheck: [],
      tableSearch: '',
      allItemsHaveFactors: false,
      pageCount: 0,
      currentItems: [],
      showDuplicateAlert: false,
      tempStorageKey: null,
    };
  },
  async created() {
    this.defaultConfig = { ...this.config };
    this.tempStorageKey = `factorDialog_temp_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`;
    this.restoreTempData();

    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "CALC_WITH_FACTOR",
      info: "CALC_WITH_FACTOR",
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        this.config = {
          ...newItem.props,
          format: newItem.props.format || {
            region: null,
            afterPoint: null
          },
          subtype: "CALC_WITH_FACTOR",
          info: "CALC_WITH_FACTOR",
        };
      }
    },
    valid() {
      this.$emit("update:validation", this.valid);
    },
    tableData: {
      handler() {
        this.validateAllFactors();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      requireRules: 'general/requireRules',
      process: 'authority/authoritiesProcess',
    }),
    isValid() {
      return this.processId && this.groupId && this.listStructureId && this.numberStructureId;
    },
    hasValidFactors() {
      return this.tableData.length > 0 && this.allItemsHaveFactors;
    },
  },
  methods: {
    generateUniqueKey(item) {
      return `${item.processId}_${item.groupId}_${item.listStructureId}_${item.numberStructureId}_${item.nestedValue}`;
    },
    
    customFilter(value, search, item) {
      if (search == null || search === '') return true;
      
      search = search.toString().toLowerCase();
      
      const processName = this.translate(item.processName).toLowerCase();
      const groupName = this.translate(item.groupName).toLowerCase();
      const listStructureName = this.translate(item.listStructureName).toLowerCase();
      const numberStructureName = this.translate(item.numberStructureName).toLowerCase();
      const nestedValue = (item.nestedValue || '').toString().toLowerCase();
      const factor = (item.factor || '').toString().toLowerCase();
      
      return processName.includes(search) || 
             groupName.includes(search) || 
             listStructureName.includes(search) || 
             numberStructureName.includes(search) || 
             nestedValue.includes(search) || 
             factor.includes(search);
    },
    
    getTextFromLanguageArray(arr) {
      if (!arr || !Array.isArray(arr) || arr.length === 0) return '';
      
      const englishItem = arr.find(item => item.language === 'ENGLISH');
      if (englishItem) return englishItem.label || '';
      
      return arr[0].label || '';
    },
    
    validateAllFactors() {
      this.allItemsHaveFactors = this.tableData.length > 0 && this.tableData.every(item => {
        const factorValue = parseFloat(item.factor);
        return factorValue !== null && !isNaN(factorValue) && factorValue > 0;
      });
      
      this.$emit("update:validation", this.valid && this.allItemsHaveFactors);
    },
    addToTable() {
      if (!this.isValid) return;

      const processObj = this.process.find(p => p.id === this.processId);
      const groupObj = this.groups.find(g => g.id === this.groupId);
      const listStructureObj = this.listStructures.find(s => s.id === this.listStructureId);
      const numberStructureObj = this.numberStructures.find(s => s.id === this.numberStructureId);

      const newItem = {
        processId: this.processId,
        groupId: this.groupId,
        listStructureId: this.listStructureId,
        nestedValue: this.nestedValue,
        numberStructureId: this.numberStructureId
      };
      
      const newItemKey = this.generateUniqueKey(newItem);

      const duplicateItem = this.tableData.find(item => 
        this.generateUniqueKey(item) === newItemKey
      );

      if (duplicateItem) {
        this.showDuplicateAlert = true;
        return;
      }

      this.tableData.unshift({
        id: null,
        processId: this.processId,
        processName: processObj?.languageKey || processObj?.proccess_name || '',
        groupId: this.groupId,
        groupName: groupObj?.languageKey || groupObj?.name || '',
        listStructureId: this.listStructureId,
        listStructureName: listStructureObj?.name || listStructureObj?.value || '',
        nestedValue: this.nestedValue,
        numberStructureId: this.numberStructureId,
        numberStructureName: numberStructureObj?.name || numberStructureObj?.value || '',
        factor: null,
        _uniqueKey: newItemKey
      });

      // Reset form fields
      this.processId = null;
      this.groupId = null;
      this.listStructureId = null;
      this.nestedValue = null;
      this.numberStructureId = null;
      
      // Clear derived data
      this.listStructures = [];
      this.numberStructures = [];
      this.nestedValues = [];
      this.groups = [];
    },

    removeFromTable(index) {
      const itemToRemove = this.currentItems[index];
      
      if (!itemToRemove) return;
      
      const uniqueKey = this.generateUniqueKey(itemToRemove);
      const actualIndex = this.tableData.findIndex(item => 
        this.generateUniqueKey(item) === uniqueKey
      );
      
      if (actualIndex >= 0) {
        this.tableData.splice(actualIndex, 1);
        this.validateAllFactors();
      }
    },

    tableDataToParams() {
      return this.tableData.map(item => ({
        id: item.id || null,
        processId: item.processId,
        groupId: item.groupId,
        listStructureId: item.listStructureId,
        nestedValue: item.nestedValue,
        numberStructureId: item.numberStructureId,
        factor: parseFloat(item.factor) || 0
      }));
    },

    paramsToTableData(params) {
      if (!params || !Array.isArray(params) || params.length === 0) {
        return [];
      }

      return params.map(param => ({
        id: param.id || null,
        processId: param.processId || null,
        processName: param.processName || '',
        groupId: param.groupId || null,
        groupName: param.groupName || '',
        listStructureId: param.listStructureId || null,
        listStructureName: param.listStructureName || '',
        nestedValue: param.nestedValue || '',
        numberStructureId: param.numberStructureId || null,
        numberStructureName: param.numberStructureName || '',
        factor: param.factor || 0
      }));
    },

    saveTempData() {
      if (this.tableData && this.tableData.length > 0 && this.dialog) {
        const tempData = {
          tableData: this.tableData,
          config: this.config,
          timestamp: new Date().getTime()
        };
        localStorage.setItem(this.tempStorageKey, JSON.stringify(tempData));
        
        if (this.item && this.item.id) {
          localStorage.setItem(`factorDialog_item_${this.item.id}`, this.tempStorageKey);
        }
      }
    },

    restoreTempData() {
      let lookupKey = null;
      if (this.item && this.item.id) {
        lookupKey = localStorage.getItem(`factorDialog_item_${this.item.id}`);
      }
      
      if (lookupKey) {
        const savedData = JSON.parse(localStorage.getItem(lookupKey) || null);
        if (savedData && savedData.tableData) {
          const maxAge = 8 * 60 * 60 * 1000;
          if (new Date().getTime() - savedData.timestamp < maxAge) {
            this.tableData = savedData.tableData;
            this.config = {...this.config, ...savedData.config};
            this.$nextTick(() => {
              this.validateAllFactors();
            });
            return true;
          }
        }
        localStorage.removeItem(lookupKey);
        localStorage.removeItem(`factorDialog_item_${this.item.id}`);
      }
      
      return false;
    },

    clearTempData() {
      if (this.tempStorageKey) {
        localStorage.removeItem(this.tempStorageKey);
      }
      
      if (this.item && this.item.id) {
        localStorage.removeItem(`factorDialog_item_${this.item.id}`);
      }
    },

    saveConfig(data) {
      this.validateAllFactors();
      
      if (!this.allItemsHaveFactors) {
        this.$store.dispatch("notification/show", {
          type: "error",
          message: this.$t('widgets.dialogs.alerts.cannotSaveMissingFactors') || 'Cannot save. Some items are missing factor values.'
        });
        return;
      }

      const parsedTableData = this.tableData.map(item => ({
        id: item.id || null,
        processId: item.processId,
        processName: item.processName,
        groupId: item.groupId,
        groupName: item.groupName,
        listStructureId: item.listStructureId,
        listStructureName: item.listStructureName,
        nestedValue: item.nestedValue,
        numberStructureId: item.numberStructureId,
        numberStructureName: item.numberStructureName,
        factor: parseFloat(item.factor) || 0
      }));

      const config = {
        ...data,
        filter: {
          params: parsedTableData
        },
        initialized: true
      };

      // Save the table data to localStorage using the widget ID
      if (this.item.id) {
        localStorage.setItem(`factorWidget_${this.item.id}`, JSON.stringify({
          tableData: this.tableData,
          timestamp: new Date().getTime()
        }));
      }

      // Clear temp data for dialog editing
      this.clearTempData();

      this.$emit('update:config', config);
      this.$emit('update:validation', this.valid);
      this.dialog = false;
    },

    async handleInitData() {
      this.loadingFields = true;
      
      try {
        // console.log("Item props in handleInitData:", JSON.stringify(this.item.props));
        
        this.config = { ...this.item.props };

        if (this.item.props?.filter?.params && Array.isArray(this.item.props.filter.params)) {
          // console.log("Loading existing params:", JSON.stringify(this.item.props.filter.params));
          
          this.tableData = this.item.props.filter.params.map(param => {
            return {
              id: param.id || null,
              processId: param.processId || null,
              processName: param.processName || '',
              groupId: param.groupId || null,
              groupName: param.groupName || '',
              listStructureId: param.listStructureId || null,
              listStructureName: param.listStructureName || '',
              nestedValue: param.nestedValue || '',
              numberStructureId: param.numberStructureId || null,
              numberStructureName: param.numberStructureName || '',
              factor: param.factor || 0,
              _uniqueKey: `${param.processId}_${param.groupId}_${param.listStructureId}_${param.numberStructureId}_${param.nestedValue}`
            };
          });
          
          // console.log("Processed table data:", this.tableData);
          
          this.$nextTick(() => {
            this.validateAllFactors();
          });
        } 
        else if (!this.item.initialized) {
          this.config = { ...this.defaultConfig };
          this.tableData = [];
        }
        else if (this.item.id) {
          const savedWidgetData = localStorage.getItem(`factorWidget_${this.item.id}`);
          if (savedWidgetData) {
            try {
              const parsedData = JSON.parse(savedWidgetData);
              if (parsedData && parsedData.tableData) {
                this.tableData = parsedData.tableData;
                // console.log("Restored table data from localStorage:", this.tableData);
                this.$nextTick(() => {
                  this.validateAllFactors();
                });
              }
            } catch (e) {
              console.error("Error parsing saved widget data:", e);
              this.tableData = [];
            }
          } else {
            this.tableData = [];
          }
        } else {
          this.tableData = [];
        }
      } catch (error) {
        console.error("Error in handleInitData:", error);
        this.tableData = [];
      } finally {
        this.loadingFields = false;
      }
    },

    clear(e) {
      this.config = { ...e }
      this.saveConfig(e)
    },

    async getStructuresAndValues() {
      if (this.groupId) {
        await this.getListStructures();
        await this.getNumberStructures();
        
        this.nestedValues = [];
        this.nestedValue = null;
      }
    },

    async loadNestedValues() {
      if (!this.listStructureId) {
        this.nestedValues = [];
        this.nestedValue = null;
        return;
      }

      try {
        await this.getNestedValues(this.listStructureTypes);
        
        this.nestedValue = null;
      } catch (error) {
        console.error('Error loading nested values:', error);
        this.nestedValues = [];
      }
    },
  }
};
</script>

<style scoped>
.factor-table ::v-deep th {
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.factor-table ::v-deep .v-data-table__wrapper {
  border-radius: 0 0 4px 4px;
}

.factor-input ::v-deep .v-input__control {
  min-height: 32px !important;
}

.factor-input ::v-deep .v-text-field__slot input {
  font-size: 14px;
  text-align: right;
  padding-right: 8px;
}

.duplicate-alert {
  position: relative;
  margin: 16px 0;
  padding: 12px 16px;
  border: 1px solid #f57c00;
  border-radius: 4px;
  background-color: #fff3e0;
}

.alert-content {
  display: flex;
  align-items: center;
}
</style>
