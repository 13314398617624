import NumberFormatService from "@/services/NumberFormatService";

export const ChartBaseMixin = {
  methods: {
    getBaseChartConfig() {
      const currentLocale = this.$i18n.locale;
      const chartTitle = this.item?.props?.title || 'chart';
      const localeConfig = {
        name: currentLocale,
        options: {
          toolbar: {
            exportToSVG: this.$t('common.chart.toolbar.downloadMenu.exportToSVG'),
            exportToPNG: this.$t('common.chart.toolbar.downloadMenu.exportToPNG'),
            exportToCSV: this.$t('common.chart.toolbar.downloadMenu.exportToCSV'),
            menu: this.$t('common.chart.toolbar.menu'),
            selection: this.$t('common.chart.toolbar.selection'),
            selectionZoom: this.$t('common.chart.toolbar.selectionZoom'),
            zoomIn: this.$t('common.chart.toolbar.zoomIn'),
            zoomOut: this.$t('common.chart.toolbar.zoomOut'),
            pan: this.$t('common.chart.toolbar.pan'),
            reset: this.$t('common.chart.toolbar.reset')
          }
        }
      };

      return {
        chart: {
          locales: [localeConfig],
          defaultLocale: currentLocale,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              menu: '<i class="mdi mdi-dots-vertical mdi-24px" title="' + this.$t('common.chart.toolbar.menu') + '"></i>',
              selection: '<i class="mdi mdi-select mdi-24px" title="' + this.$t('common.chart.toolbar.selection') + '"></i>',
              zoom: '<i class="mdi mdi-magnify mdi-24px" title="' + this.$t('common.chart.toolbar.zoom') + '"></i>',
              zoomin: '<i class="mdi mdi-magnify-plus mdi-24px" title="' + this.$t('common.chart.toolbar.zoomIn') + '"></i>',
              zoomout: '<i class="mdi mdi-magnify-minus mdi-24px" title="' + this.$t('common.chart.toolbar.zoomOut') + '"></i>',
              pan: '<i class="mdi mdi-drag-variant mdi-24px" title="' + this.$t('common.chart.toolbar.pan') + '"></i>',
              reset: '<i class="mdi mdi-refresh mdi-24px" title="' + this.$t('common.chart.toolbar.reset') + '"></i>'
            },
            autoSelected: 'zoom',
            export: {
              csv: {
                filename: `${chartTitle}_${new Date().toISOString().split('T')[0]}`,
                headerCategory: 'Data',
                headerValue: chartTitle,
                columnDelimiter: ';', // Cambiar a punto y coma para mejor compatibilidad
                columnWidth: 20, // Ancho fijo para columnas
                formatRows: true, // Habilitar formato de filas
                categoryFormatter: function(value) {
                  if (!value) return '';
                  // Si el valor ya incluye un formato de fecha, lo dejamos como está
                  if (typeof value === 'string' && (value.includes('/') || value.includes('-'))) {
                    return value.padEnd(15, ' '); // Padding para alinear
                  }
                  // Si es una fecha, la formateamos
                  if (value instanceof Date) {
                    return value.toLocaleDateString().padEnd(15, ' ');
                  }
                  return String(value).padEnd(15, ' ');
                },
                valueFormatter: function(value) {
                  if (value === null || value === undefined) return '0'.padStart(10, ' ');
                  if (typeof value === 'number') {
                    try {
                      return value.toString().replace('.', ',').padStart(10, ' ');
                    } catch (error) {
                      console.error('Error formatting number:', error);
                      return '0'.padStart(10, ' ');
                    }
                  }
                  return (value || '0').padStart(10, ' ');
                }
              },
              svg: {
                filename: `${chartTitle}_${new Date().toISOString().split('T')[0]}`
              },
              png: {
                filename: `${chartTitle}_${new Date().toISOString().split('T')[0]}`
              }
            }
          }
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => value,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            trim: true,
            maxHeight: 120
          },
          tickPlacement: 'on'
        },
        dataLabels: {
          enabled: false
        }
      };
    },
    updateBaseChartLocale() {
      if (!this.chartOptions) return;
      
      const currentLocale = this.$i18n.locale;
      const localeConfig = {
        name: currentLocale,
        options: {
          toolbar: {
            exportToSVG: this.$t('common.chart.toolbar.downloadMenu.exportToSVG'),
            exportToPNG: this.$t('common.chart.toolbar.downloadMenu.exportToPNG'),
            exportToCSV: this.$t('common.chart.toolbar.downloadMenu.exportToCSV'),
            menu: this.$t('common.chart.toolbar.menu'),
            selection: this.$t('common.chart.toolbar.selection'),
            selectionZoom: this.$t('common.chart.toolbar.selectionZoom'),
            zoomIn: this.$t('common.chart.toolbar.zoomIn'),
            zoomOut: this.$t('common.chart.toolbar.zoomOut'),
            pan: this.$t('common.chart.toolbar.pan'),
            reset: this.$t('common.chart.toolbar.reset')
          }
        }
      };

      this.chartOptions.chart.locales = [localeConfig];
      this.chartOptions.chart.defaultLocale = currentLocale;
      
      this.chartOptions.chart.toolbar.tools = {
        menu: '<i class="mdi mdi-dots-vertical mdi-24px" title="' + this.$t('common.chart.toolbar.menu') + '"></i>',
        selection: '<i class="mdi mdi-select mdi-24px" title="' + this.$t('common.chart.toolbar.selection') + '"></i>',
        zoom: '<i class="mdi mdi-magnify mdi-24px" title="' + this.$t('common.chart.toolbar.zoom') + '"></i>',
        zoomin: '<i class="mdi mdi-magnify-plus mdi-24px" title="' + this.$t('common.chart.toolbar.zoomIn') + '"></i>',
        zoomout: '<i class="mdi mdi-magnify-minus mdi-24px" title="' + this.$t('common.chart.toolbar.zoomOut') + '"></i>',
        pan: '<i class="mdi mdi-drag-variant mdi-24px" title="' + this.$t('common.chart.toolbar.pan') + '"></i>',
        reset: '<i class="mdi mdi-refresh mdi-24px" title="' + this.$t('common.chart.toolbar.reset') + '"></i>'
      };
    }
  }
};
