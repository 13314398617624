<template>
  <v-dialog v-model="showDialog" max-width="600px" persistent>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn class="show__btn" v-bind="attrs" small text @click.stop="openDialog">
        <v-icon color='black' left small>mdi-send-outline</v-icon>
        {{ $t('dashboard.buttons.send') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline secondary t-bw-secondary--text">{{ $t('dashboard.dialogs.send.title') }}</v-card-title>
      <v-card-text class="pa-6">
        <v-alert border='left' class='mt-2' color='orange' dense icon='mdi-alert-outline' text type='warning'>
          {{ $t('dashboard.dialogs.send.alert') }}
        </v-alert>
        <v-autocomplete
          color="primary"
          item-color="primary"
          return-object
          v-model="selectedUsers"
          :items="filteredUsers"
          :label="$t('dashboard.dialogs.send.fields.users')"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          small-chips
          clearable
          deletable-chips
        >
          <template
            v-slot:selection="{ item, index, attrs, select, selected }"
          >
            <v-chip v-if="index === 0" small>
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedUsers.length - 1 }}
                      {{ $t("common.titles.others") }})
                    </span>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small text color="neuter"  @click="closeDialog" :disabled="loading">{{ $t('dashboard.buttons.close') }}</v-btn>
        <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="sendDashboard" :disabled="selectedUsers.length === 0" :loading="loading">
          {{ $t('dashboard.buttons.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'SendDialog',
    props: {
      layout: Object
    },
    data() {
      return {
        showDialog: false,
        selectedUsers: [],
        loading: false,
        profile: JSON.parse(localStorage.getItem('profile'))
      };
    },
    computed: {
      ...mapGetters({
        users: "authority/authoritiesForLocations"
      }),
      filteredUsers() {
        return this.users.filter(user => user.id !== this.profile.id);
      }
    },
    methods: {
      ...mapActions({
        fetchListAuthority: 'authority/fetchListAuthority',
        sendDashboardAction: 'dashboard/sendDashboard'
      }),
      openDialog() {
        this.$emit('close');
        this.showDialog = true;
      },
      async sendDashboard() {
        if (this.selectedUsers.length > 0) {
          this.loading = true;
          await this.sendDashboardAction({
            dashboardId: this.layout.id,
            recipients: this.selectedUsers.map(s => s.id)
          });
          this.loading = false;
          this.closeDialog();
        }
      },
      closeDialog() {
        this.selectedUsers = [];
        this.showDialog = false;
      }
    }
  };
</script>

<style scoped>
  .overlay-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2em .2em 0 0 !important;
  }

  .show__btn.v-btn {
    width: 100%;
  }

  >>> span.v-btn__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
</style>
