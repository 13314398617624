<template>
  <v-overlay v-if="loadingOverlay" absolute color="white" opacity=".9">
    <div class="d-flex flex-column align-center justify-center">
      <div class="px-4 py-2 secondary lighten-1 secondary font-weight-medium text-center" style="font-size: 14px; border-radius: .3em; width: 100%">
        <span class="t-bw-secondary--text">{{ $t('dashboard.notifications.loading') }}</span>
        <v-progress-circular class="ml-2" indeterminate color="t-bw-secondary" size="20" width="3"></v-progress-circular>
      </div>
    </div>
  </v-overlay>
  <div v-else class="fill-height">
    <WidgetsSelectorDrawer>
      <template v-slot:prepend>
        <v-toolbar dense class="pr-4 pl-2" :class="[{ 'toolbar__editing-mode': !isEditingEnabled }, { 'toolbar__not-editing-mode': isEditingEnabled}]">
          <v-row>
            <v-col :cols="!isEditingEnabled ? 4 : 5" align-self="center" class="d-flex justify-start align-center">
              <div style="width: 50%">
                <v-autocomplete
                  v-if="!isEditingEnabled"
                  item-color="primary"
                  hide-details
                  outlined
                  dense
                  v-model="layout"
                  :items="layouts"
                  item-text="name"
                  return-object
                  color="primary"
                  background-color="grey lighten-4"
                  :placeholder="$t('dashboard.placeholder')"
                  :no-data-text="$t('dashboard.add')"
                  @keydown.enter="addNewLayout($event.target.value)"
                  @change="isEmptyLayout($event)"
                  :filter="customFilter"
                  :disabled="isEditingEnabled"
                  :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
                  :hide-no-data="true"
                  :key="layout.id"
                >
                  <template v-slot:append-outer>
                    <GlobalFilters v-if="layout && !isEditingEnabled" />
                    <MoreOptions />
                  </template>
                  <template v-slot:no-data>
                    <v-list-item style="height: 40%" v-if="check([{ domain: 'Dashboard', permisions: ['Write'] }]) || checkAdmin()">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{$t('dashboard.add')}}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                      <span style="background-color: rgba(0,212,172,0.07); border-radius: .3em" class="pa-1">
                        <v-icon color="green" right>mdi-keyboard-return</v-icon>
                      </span>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item @click="selectLayout(item)">
                      <v-list-item-content>
                        <div class="d-flex justify-space-between cursor-pointer">
                          <span>
                            <span class="font-weight-regular">{{ item.name }}</span>
                            <v-icon v-if="item.selected" small color="yellow" class="ml-2">mdi-star</v-icon>
                            <v-icon v-if="item.current" small color="green" class="ml-2">mdi-check-circle-outline</v-icon>
                          </span>
                          <v-icon v-if="grant(item, 'DELETE')" class="cursor-pointer" :disabled="item.selected || item.current" small color="error" @click.stop="confirmDelete(item)">mdi-trash-can-outline</v-icon>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model="layout.name"
                  hide-details
                  outlined
                  dense
                  color="primary"
                  background-color="grey lighten-4"
                  :disabled="!isEditingEnabled"
                >
                  <template v-slot:append-outer>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small
                               v-if="layout && isEditingEnabled"
                               v-on="on"
                               v-bind="attrs"
                               @click.stop="openConfigDialog"
                               class="pt-1"
                               :disabled="!layout">
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('dashboard.config')}}</span>
                    </v-tooltip>

                    <v-dialog v-model="showConfigDialog" persistent max-width="600px">
                      <v-card>
                        <v-card-title class="headline secondary t-bw-secondary--text">{{ $t('widgets.dialogs.general.title') }}</v-card-title>
                        <v-card outlined class="ma-6">
                          <v-card-title class="grey--text text--darken-3">{{ $t('widgets.dialogs.general.section.regionalFormatNumber') }}</v-card-title>
                          <v-form ref="formGeneralConfig" v-model="validGeneralConfig">
                            <v-card-text class="pa-6" style="display: grid; grid-template-columns: 50% 50%;">
                              <v-select
                                :items="regions"
                                clearable
                                outlined
                                dense
                                color="primary"
                                v-model="generalFormat.region"
                                :label="$t('widgets.dialogs.format.region')"
                                item-text="name"
                                item-value="value"
                                item-color="primary"
                                class="pr-2"
                                :rules="selectRule"
                              />
                              <v-text-field
                                type="number"
                                clearable
                                outlined
                                dense
                                color="primary"
                                v-model.number="generalFormat.afterPoint"
                                :min="0"
                                :max="3"
                                :label="$t('widgets.dialogs.format.afterPoint')"
                                class="pl-2"
                                :rules="requireAndCompareMax(0, 3)"
                              />
                            </v-card-text>
                          </v-form>
                        </v-card>
                        <v-card-actions class="pa-6">
                          <v-spacer></v-spacer>
                          <v-btn small text color="neuter" @click="closeGlobalFormat">{{$t('common.buttons.cancel')}}</v-btn>
                          <v-btn :disabled="!validGeneralConfig" small elevation="0" color="primary" class="t-bw-primary--text" @click="saveGlobalFormat">{{$t('common.buttons.save')}}</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-text-field>

                <v-dialog v-model="showDeleteDialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title class="headline secondary t-bw-secondary--text">{{ $t('dashboard.dialogs.delete.title') }}</v-card-title>
                    <v-card-text class="pa-6">{{ $t('dashboard.dialogs.delete.text') }}</v-card-text>
                    <v-card-actions class="pa-6">
                      <v-spacer></v-spacer>
                      <v-btn small text color="neuter" @click="showDeleteDialog = false">{{$t('common.buttons.cancel')}}</v-btn>
                      <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteLayout" :loading="loading.delete">{{$t('common.buttons.confirm')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <v-col :cols="8" v-if="!isEditingEnabled" align-self="center" class="d-flex justify-end px-4">
              <v-btn text @click="isEditingEnabled = true" v-if="grant(layout, 'WRITE') && (check([{ domain: 'Dashboard', permisions: ['Update'] }]) || checkAdmin())">
                {{ $t('dashboard.edit') }}
                <v-icon right>mdi-view-dashboard-edit-outline</v-icon>
              </v-btn>
              <v-btn text disabled v-else-if="(layout && layout.id) && grant(layout, 'READ')">
                {{ $t('dashboard.badges.shared') }}
                <v-icon right>mdi-view-dashboard</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:append>
        <v-overlay v-if="loadingToolbar" color="white" absolute class="mt-6" style="top: 48px" opacity="1">
          <div class="d-flex flex-row justify-space-between align-center align-items-center pa-1"
               style="background-color: rgb(239,239,239);
                      border-radius: 0.3em;
                      color: #515151;"
               >
            <p class="ma-0 pa-0 mx-2 text--white">{{$t('dashboard.notifications.loading')}}</p>
            <v-progress-circular color="dark" indeterminate size="16" width="2" class="mx-2"></v-progress-circular>
          </div>
        </v-overlay>
        <FilterChipsToolbar
          v-if="!(loadingLayout || loadingOverlay)"
          @on:loading="loadingToolbar = $event"
          :filters="filters"
          :hasActiveFilters="hasActiveFilters"
          :isEditingEnabled="isEditingEnabled"
          :isLocalStoragePresent="isLocalStoragePresent"
          :layout="layout"
          :key="layout.id"
        />
      </template>
    </WidgetsSelectorDrawer>
    <v-overlay v-if="loadingLayout || loadingOverlay" absolute color="white" opacity=".9">
      <v-chip class="overlay-chip" color="grey lighten-5" text-color="grey">
        <div>
          <v-progress-circular indeterminate color="primary" size="20" width="3" class="mr-2"></v-progress-circular>
          {{ $t('dashboard.notifications.loading') }}
        </div>
      </v-chip>
    </v-overlay>
    <DashboardGrid v-else :style="(hasActiveFilters && !isEditingEnabled) || loadingToolbar ? 'top: 96px; position: relative' : 'top: 48px; position: relative'" @on:cancel="cancel()" />
  </div>
</template>
<script>
import '@dashboard/styles/main.css';

import {mapGetters, mapActions} from "vuex";
import {FormWidgetMixin} from "@/module/dashboard/mixins/form-widget.mixin";
import DashboardGrid from '@dashboard/layouts/DashboardGrid.vue';
import WidgetsSelectorDrawer from "@dashboard/components/WidgetsSelectorDrawer.vue";
import PermisionService from "@/services/PermisionService";
import PermissionService from "@/services/PermisionService";
import GlobalFilters from "@/module/dashboard/components/common/filters/GlobalFilters.vue";
import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin"
import FilterChipsToolbar from "@/module/dashboard/components/common/filters/FilterChipsToolbar.vue"
import ShareDialog from "@/module/dashboard/components/common/ShareDialog.vue"
import MoreOptions from "@dashboard/components/common/MoreOptions.vue"

export default {
  name: 'DashboardView',
  components: {
    MoreOptions,
    ShareDialog,
    FilterChipsToolbar,
    GlobalFilters,
    WidgetsSelectorDrawer,
    DashboardGrid
  },
  mixins: [FormWidgetMixin, FilterWidgetMixin],
  data() {
    return {
      showDeleteDialog: false,
      layoutToDelete: null,
      showShareDialog: false,
      showConfigDialog: false,
      selectedUsers: [],
      users: [],
      loading: {
        delete: false,
        shared: false
      },
      originalLayoutName: null,
      loadingOverlay: false,
      onInit: true,
      reloadLayoutId: null,
      validGeneralConfig: false,
      loadingLayout: false,
      loadingToolbar: false,
      profile: null,
      originalLayout: null
    }
  },
  async created() {
    this.profile = JSON.parse(localStorage.getItem('profile'));

    this.isEditingEnabled = false;

    await this.$store.dispatch('dashboard/fetchUserLayouts');
    console.log('layouts', _.map(this.layouts, (l) => {
      return {
        name: l.name,
        widgets: _.map(l.widgets, (w) => {
          return w.i
        })
      }
    }))
    const defaultLayout = this.layouts.find(layout => layout.selected);

    if (defaultLayout) {
      defaultLayout.current = true;
      this.originalLayoutName = defaultLayout.name;
      this.$store.commit('dashboard/SET_LAYOUT', defaultLayout);

      this.generalFormat = {...this.layout.format};
    }

    await this.$store.dispatch('authority/fetchListAuthority', [this.profile, this.$toast]).then(() => {
      this.users = _.filter(this.authorities, (user) => {
        if (user.id !== this.profile.id) return user;
      });
    });
  },
  async beforeMount() {
    this.loadingOverlay = true;
    await this.$store.dispatch('authority/fetchListAuthorityProcess', [this.profile.id, this.$toast]).finally(() => {
      this.onInit = false;
      this.loadingOverlay = false;

      // setTimeout(() => {
      //   console.log('event: on:load-filters')
      //   this.$root.$emit('on:load-filters')
      //   this.$root.$emit('on:recalculate');
      //   this.$root.$emit('apply:dashboard-filters', true);
      // }, 0)
    })
  },
  async mounted() {
    this.$root.$on('reload:layout', async (data) => {
      this.reloadLayoutId = data.layoutId;
      this.loadingOverlay = true;
      await this.reloadLayout();
      this.loadingOverlay = false;
    });
  },
  async updated() {
    if (!this.isLocalStoragePresent)
      await this.saveFilters({
        layoutId: this.layout.id, isGlobal: false
      })
  },
  computed: {
    ...mapGetters({
      authorities: "authority/authoritiesForLocations"
    }),
    isLocalStoragePresent() {
      const profile = JSON.parse(localStorage.getItem('profile'))
      let filters = JSON.parse(
        localStorage.getItem(`filters-${profile.company_id}-${this.layout.id}`) ||
        localStorage.getItem(`filters-${profile.company_id}-global`)
      )

      console.log('isLocalStoragePresent >>>>', filters)

      return !!filters;
    },
    filters: {
      get() {
        return this.$store.getters['dashboard/GET_FILTERS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_FILTERS', val);
      }
    },
    generalFormat: {
      get() {
        return this.$store.getters['dashboard/GENERAL_FORMAT'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_GENERAL_FORMAT', val);
      }
    },
    layout: {
      get() {
        return this.$store.getters['dashboard/LAYOUT'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_LAYOUT', val);
      }
    },
    layouts: {
      get() {
        return this.$store.getters['dashboard/LAYOUTS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_LAYOUTS', val);
      }
    },
    isEditingEnabled: {
      get() {
        return this.$store.getters['dashboard/IS_EDITING_MODE'];
      },
      set(val) {
        this.$store.dispatch('dashboard/toggleEditMode', val);
      }
    },
    hasActiveFilters() {
      return this.filters.dates.length > 0 ||
        this.filters.recordDates.length > 0 ||
        this.filters.primaryLocations.length > 0 ||
        this.filters.secondaryLocations.length > 0 ||
        this.filters.processes.length > 0 ||
        this.filters.stages.length > 0 ||
        this.filters.classificationTypes.length > 0
    }
  },
  methods: {
    ...mapActions({
      saveFilters: "dashboard/saveFilters",
    }),
    closeGlobalFormat () {
      this.generalFormat = {
          ...JSON.parse(localStorage.getItem('current:format')) || {
          region: 'en-us',
          afterPoint: 2
        },
        updated: false
      };

      this.showConfigDialog = false;
    },
    saveGlobalFormat () {
      this.generalFormat.updated = true;
      // this.layout.format = {...this.generalFormat};

      localStorage.setItem('current:format', JSON.stringify(this.layout.format));
      this.showConfigDialog = false;
    },
    async reloadLayout() {
      try {
        await this.$store.dispatch('dashboard/fetchUserLayouts');

        const layout = _.find(this.layouts,  {id: this.reloadLayoutId});

        if (layout) {
          layout.current = true;
          this.$store.commit('dashboard/SET_LAYOUT', layout);
        } else {
          const selected = _.find(this.layouts,  {selected: true});

          if (selected) {
            selected.current = true;
            this.$store.commit('dashboard/SET_LAYOUT', selected);
          } else {
            this.$store.commit('dashboard/SET_LAYOUT', {
              id: null,
              name: null,
              selected: false,
              widgets: [],
              isSaved: false,
              user: {
                permissions: [],
                personInChargeId: null
              }
            });
          }

          this.generalFormat = {...this.layout.format};
        }

        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (error) {
        console.error("Error reloading layout:", error);
      }
    },
    grant(layout, permission) {
      if (layout && layout.user)
        return layout.user.permissions.includes(permission);
      else
        return false;
    },
    check(permissions) {
      return PermisionService.check(permissions);
    },
    checkAdmin() {
      return PermissionService.checkAdmin()
    },
    customFilter(item, queryText, itemText) {
      if (queryText === '') {
        return true;
      }

      return itemText.toLocaleLowerCase() === queryText.toLocaleLowerCase();
    },
    openConfigDialog() {
      localStorage.setItem('current:format', JSON.stringify(this.layout.format));
      this.showConfigDialog = true;
    },
    isEmptyLayout(e) {
      if (!e) {
        if (this.originalLayout) {
          this.layout = this.originalLayout;
          this.originalLayout.current = true;
        } else {
          const currentLayout = _.find(this.layouts, { current: true });
          if (currentLayout) {
            this.layout = currentLayout;
          } else {
            // Si no hay ningún layout actual, selecciona el primero de la lista (sin lógica definida)
            this.layout = this.layouts[0];
            this.layout.current = true;
          }
        }
      }
    },
    cancel() {
      if (this.originalLayout) {
        this.layout.name = this.originalLayout.name;
        this.layout = this.originalLayout;
        this.layout.current = true;
        this.originalLayout = null;
      }
      this.isEditingEnabled = false;
    },
    addNewLayout(layoutName) {
      console.log(this.check([{ domain: 'Dashboard', permisions: ['Write'] }]))
      if (!(this.check([{ domain: 'Dashboard', permisions: ['Write'] }]) || this.checkAdmin())) return;

      if (!layoutName) return;

      if (this.layouts.find(layout => layout.name === layoutName)) {
        return;
      }

      const newLayout = {
        widgets: [],
        id: null,
        name: layoutName,
        selected: false,
        isSaved: false,
        current: true
      };

      // Guardar el layout original antes de cambiar
      this.originalLayout = this.layout;

      this.layouts.push(newLayout);

      if (this.layout)
        this.layout.current = false;

      this.layout = newLayout;
      this.originalLayoutName = layoutName;

      this.generalFormat = {
        region: 'en-us',
        afterPoint: 2
      };

      this.isEditingEnabled = true;
    },
    selectLayout(item) {
      console.log(item)
      this.loadingLayout = true;

      this.layouts.forEach(layout => {
        layout.current = false;
      });
      item.current = true;
      this.layout = {...item};
      this.originalLayoutName = item.name;

      this.generalFormat = {...this.layout.format};

      this.layouts = this.layouts.filter(layout => layout.id);

      this.$store.commit('dashboard/CLEAR_FILTERS');

      setTimeout(() => {
        this.loadingLayout = false;
      }, 2000);
    },
    confirmDelete(item) {
      this.layoutToDelete = item;
      this.showDeleteDialog = true;
    },
    async deleteLayout() {
      if (!this.layoutToDelete || this.layoutToDelete.current) return;

      this.loading.delete = true;
      await this.$store.dispatch('dashboard/removeSharedDashboard', this.layoutToDelete.id);
      this.loading.delete = false;

      this.layouts = this.layouts.filter(layout => layout.id !== this.layoutToDelete.id);
      this.showDeleteDialog = false;

      if (!this.layout.current) {
        this.layout = null;
      }

      this.layoutToDelete = null;
    },
  },

  async beforeDestroy() {
    await this.$root.$emit("set:location:origin", {
      locations: [],
      storageDevice: []
    })
    await this.$root.$emit("set:location:destination", {
      locations: [],
      storageDevice: []
    })
    this.$root.$off('reload:layout');
  }
};
</script>

<style scoped>
  .inactive-chip {
    background-color: transparent !important;
    cursor: default !important;
  }

  :deep(.v-text-field--outlined fieldset) {
    border-color: #eaeaea;
  }

  :deep(.v-sheet.v-toolbar:not(.v-sheet--outlined)) {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  }

  .toolbar__editing-mode,
  .toolbar__not-editing-mode,
  .toolbar__filters {
    position: fixed;
  }

  .toolbar__editing-mode {
    width: calc(100% - 256px);
    z-index: 2;
  }

  .toolbar__not-editing-mode {
    width: calc(100% - 556px);
    z-index: 2;
  }

  .toolbar__with-filters {
    margin-bottom: 48px;
  }

  .toolbar__filters {
    margin-top: 48px;
    width: 100%;
    z-index: 1;
  }

  .v-list-item {
    height: 40px;
  }

  .v-list-item__content {
    margin: 0 !important;
    padding: 0 !important;
  }

  .inactive-chip {
    background-color: transparent !important;
    cursor: default !important;
  }

  .v-chip {
    border-radius: .3em !important;
    font-weight: 500;
    color: #0f3146;
    background-color: rgba(3, 172, 140, 0.02) !important;
    margin: 0 5px 0 0;
  }



  .chip {
    padding: .45rem;
    border-radius: .3em !important;
    font-weight: 500;
    color: #0f3146;
    background-color: rgba(3, 172, 140, 0.02) !important;
    border: 1px solid rgba(15, 49, 70, 0.2);
    margin: 0 5px 0 0;
  }

  span :deep(.v-chip__content) {
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
  }

  :deep(.v-select.v-input--dense .v-chip) {
    border-radius: .3em !important;
  }

  .overlay-chip {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2em .2em 0 0 !important;
  }
</style>

