<template>
  <LoadingWrapper :loading="loadingFields && !isLoading">
    <v-card outlined class="mt-2 mb-8">
      <v-row class="pa-4">
        <v-col cols="12">
          <v-autocomplete
            :items="widgetProcess"
            :no-data-text="$t('common.fields.noDataAvailable')"
            :clearable="true"
            v-model="selectedProcess"
            item-color="primary"
            item-text="proccess_name"
            item-value="id"
            dense
            flat
            outlined
            color="primary"
            :loading="loading.process"
            :rules="isMultiple ? selectRule : requireRules"
            @change="onSelectedProcess()"
            :menu-props="{ closeOnContentClick: false }"
            :multiple="isMultiple"
          >
            <template v-slot:prepend-item v-if="isMultiple">
              <v-list-item @click="() => toggleSelection('widgetProcess')">
                <v-list-item-action>
                  <v-icon color="primary">{{ computeSelectionIcon('widgetProcess') }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  {{ selectedProcess.length === widgetProcess.length ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:label>
              {{ $t("widgets.dialogs.filters.process") }}
              <strong class="red--text">*</strong>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.proccess_name | truncate(18) }}</span>
              </v-chip>
              <span style="font-size: 10px !important;" v-if="index === 1" class="grey--text caption">
                (+{{ selectedProcess.length - 1 }} {{ $t('common.titles.others') }})
              </span>
            </template>
            <template v-slot:item="{ item, attrs }" v-if="isMultiple">
              <div style="max-width: 200px">
                <v-checkbox
                  :input-value="attrs.inputValue"
                  color="primary"
                  class="pt-2 pb-2 mt-0"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <read-more
                      :classes="'mb-0'"
                      :text="item.proccess_name"
                      :max-chars="80"
                      :font-size="14"
                    />
                  </template>
                </v-checkbox>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            :items="groups"
            :no-data-text="$t('common.fields.noDataAvailable')"
            :clearable="true"
            :label="$t('widgets.dialogs.filters.group')"
            v-model="selectedGroup"
            item-color="primary"
            item-text="name"
            return-object
            dense
            flat
            outlined
            color="primary"
            :disabled="loading.process && groups.length === 0"
            :rules="isMultiple ? selectRule : requireRules"
            @change="getStructuresAndValues(customTypes)"
            :multiple="isMultiple"
          >
            <template v-slot:prepend-item v-if="isMultiple">
              <v-list-item @click="() => toggleSelection('groups', customTypes)">
                <v-list-item-action>
                  <v-icon color="primary">{{ computeSelectionIcon('groups') }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  {{ selectedGroup.length === groups.length ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:label>
              {{ $t("widgets.dialogs.filters.group") }}
              <strong class="red--text">*</strong>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.name | truncate(5) }}</span>
              </v-chip>
              <span style="font-size: 10px !important;" v-if="index === 1" class="grey--text caption">
                (+{{ selectedGroup.length - 1 }} {{ $t('common.titles.others') }})
              </span>
            </template>
            <template v-slot:item="{ item, attrs }" v-if="isMultiple">
              <div style="max-width: 200px">
                <v-checkbox
                  :input-value="attrs.inputValue"
                  color="primary"
                  class="pt-2 pb-2 mt-0"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <read-more
                      :classes="'mb-0'"
                      :text="item.name"
                      :max-chars="80"
                      :font-size="14"
                    />
                  </template>
                </v-checkbox>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6" v-if="showStructures">
          <v-autocomplete
            :items="displayedStructures"
            :no-data-text="$t('common.fields.noDataAvailable')"
            :clearable="true"
            @update:search-input="handleStructuresSearchChange($event)"
            v-model="selectedStructure"
            item-color="primary"
            item-text="value"
            return-object
            dense
            flat
            outlined
            color="primary"
            :rules="isMultiple ? selectRule : requireRules"
            :disabled="loading.process"
            :multiple="isMultiple"
          >
            <template v-slot:label>
              {{ $t("widgets.dialogs.filters.structure") }}
              <strong class="red--text">*</strong>
            </template>
            <template v-slot:prepend-item v-if="isMultiple">
              <v-list-item @click="() => toggleSelection('structures', types)">
                <v-list-item-action>
                  <v-icon color="primary">{{ computeSelectionIcon('structures') }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  {{ structureSelected.length === groups.length ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ item, attrs }" v-if="isMultiple">
              <div style="max-width: 180px">
                <v-checkbox
                  :input-value="attrs.inputValue"
                  color="primary"
                  class="pt-2 pb-2 mt-0"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <read-more
                      :classes="'mb-0'"
                      :text="item.value"
                      :max-chars="40"
                      :font-size="14"
                    />
                  </template>
                </v-checkbox>
              </div>
            </template>
            <template v-slot:append-item>
              <AFooterPaginatorField
                :items-length="filteredStructures.length"
                :items-per-page="pagination.structures.itemsPerPage"
                :itemPerPageText="false"
                :current-page="pagination.structures.currentPage"
                :valid="true"
                @update:current-page="(e) => updatePagination('structures', { page: e.page, itemsPerPage: e.itemsPerPage })"
                @update:items-per-page="(e) => updatePagination('structures', { page: e.page, itemsPerPage: e.itemsPerPage })"
              />
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.value | truncate(5) }}</span>
              </v-chip>
              <span style="font-size: 10px !important;" v-if="index === 1" class="grey--text caption">
                (+{{ structureSelected.length - 1 }} {{ $t('common.titles.others') }})
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6" v-if="showDetails">
          <v-autocomplete
            :multiple="isMultiple"
            ref="nestedValues"
            id="autocomplete"
            :items="displayedNestedValues"
            :no-data-text="$t('common.fields.noDataAvailable')"
            @update:search-input="handleNestedValuesSearchChange($event)"
            :clearable="true"
            v-model="data.nestedValues"
            item-color="primary"
            item-text="value"
            return-object
            small-chips
            dense
            flat
            outlined
            color="primary"
            :rules="isMultiple ? selectRule : requireRules"
            :disabled="nestedValues.length === 0"
          >
            <template v-slot:prepend-item v-if="isMultiple">
              <v-list-item @click="() => toggleSelection('nestedValues')">
                <v-list-item-action>
                  <v-icon color="primary">{{ computeSelectionIcon('nestedValues') }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  {{ dataToCheck.length === nestedValues.length ? $t('common.titles.deselectAll') : $t('common.titles.selectAll') }}
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ item, attrs }" v-if="isMultiple">
              <div style="max-width: 180px">
                <v-checkbox
                  :input-value="attrs.inputValue"
                  color="primary"
                  class="pt-2 pb-2 mt-0"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <read-more
                      :classes="'mb-0'"
                      :text="item.value"
                      :max-chars="40"
                      :font-size="14"
                    />
                  </template>
                </v-checkbox>
              </div>
            </template>
            <template v-slot:append-item>
              <AFooterPaginatorField
                :items-length="filteredNestedValues.length"
                :items-per-page="pagination.nestedValues.itemsPerPage"
                :itemPerPageText="false"
                :current-page="pagination.nestedValues.currentPage"
                :valid="true"
                @update:current-page="(e) => updatePagination('nestedValues', { page: e.page, itemsPerPage: e.itemsPerPage })"
                @update:items-per-page="(e) => updatePagination('nestedValues', { page: e.page, itemsPerPage: e.itemsPerPage })"
              />
            </template>
            <template v-slot:label>
              {{ $t('widgets.dialogs.filters.structure') }}
              <strong class="red--text">*</strong>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.value | truncate(5) }}</span>
              </v-chip>
              <span style="font-size: 10px !important;" v-if="index === 1" class="grey--text caption">
                (+{{ dataToCheck.length - 1 }} {{ $t('common.titles.others') }})
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
  </LoadingWrapper>
</template>

<script>
  import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue";
  import ReadMore from "@/components/ReadMoreComponent.vue";
  import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";
  import { GlobalWidgetMixin } from "@/module/dashboard/mixins/global-widget.mixin"
  import { CommonWidgetMixin } from "@/module/dashboard/mixins/common-widget.mixin"
  import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin"
  import ProcessService from "@/module/dashboard/services/ProcessService"
  import _ from "lodash"

  export default {
    name: 'SelectionComponent',
    components: { LoadingWrapper, ReadMore, AFooterPaginatorField },
    mixins: [GlobalWidgetMixin, CommonWidgetMixin, FormWidgetMixin],
    props: {
      showStructures: {
        type: Boolean,
        default: true
      },
      showDetails: {
        type: Boolean,
        default: true
      },
      data: {
        type: Object,
        required: true
      },
      customTypes: Object,
      isMultiple: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        loadingFields: false,
      };
    },
    computed: {
      selectedProcess: {
        get() {
          return this.isMultiple ? this.data.processIds : this.data.processId;
        },
        set(value) {
          if (this.isMultiple) {
            this.$set(this.data, 'processIds', value);
          } else {
            this.$set(this.data, 'processId', value);
          }
        }
      },
      selectedGroup: {
        get() {
          return this.isMultiple ? this.data.groupIds : this.data.groupId;
        },
        set(value) {
          if (this.isMultiple) {
            this.$set(this.data, 'groupIds', value);
          } else {
            this.$set(this.data, 'groupId', value);
          }
        }
      },
      selectedStructure: {
        get() {
          return this.isMultiple ? this.data.structureIds : this.data.structureId;
        },
        set(value) {
          if (this.isMultiple) {
            this.$set(this.data, 'structureIds', value);
          } else {
            this.$set(this.data, 'structureId', value);
          }
          console.log('selectedStructure set to:', value);
        }
      }
    },
    async mounted() {
      await this.handleInitData();
    },
    methods: {
      async handleInitData() {
        if (this.data && this.data.processId) {
          console.log(this.data);

          this.loadingFields = true;

          const gID = typeof this.data.groupId === 'object' ? this.data.groupId.id : this.data.groupId;
          console.log('group(s):', gID);

          await this.getGroups();
          this.selectedGroup = this.data.groupIds
            ? this.groups.filter(g => this.data.groupIds.includes(g.id))
            : this.groups.find(g => gID === g.id);
          console.log('group(s) selected:', this.selectedGroup);

          await this.$nextTick();

          const sID = typeof this.data.structureId === 'object' ? this.data.structureId.id : this.data.structureId;
          console.log('structure(s):', sID);

          await this.getStructuresAndValues(this.customTypes);
          this.selectedStructure = this.data.structureIds
            ? this.structures.filter(s => this.data.structureIds.includes(s.id))
            : this.structures.find(s => sID === s.id);
          console.log('structure(s) selected:', this.selectedStructure);

          this.nestedValues = this.nestedValues.filter(nv => this.data.nestedValues.includes(nv.value));

          this.loadingFields = false;
        }
      },
      async onSelectedProcess() {
        this.loadingFields = true;
        this.clearData();
        await this.getGroups();
        this.loadingFields = false;
      },
      async getGroups() {
        const payload = {
          processIds: this.isMultiple ? this.data.processIds : [this.data.processId],
          belongsTo: "ALL"
        };

        try {
          const { data } = await ProcessService.fetchGroups(payload);

          this.groups = data.map(g => ({
            ...g,
            name: this.translate(g.languageKey)
          }));
        } catch (error) {
          console.error("Error fetching groups:", error);
        }
      },
      async getStructuresAndValues(types) {
        this.loadingFields = true;

        const promises = [];
        _.map(types, (t, key) => {
          promises.push(this.getStructures(t));
          switch (key) {
            case 'unit':
              promises.push(this.getUnits(t));
              break;
            case 'product':
              promises.push(this.getProducts(t));
              break;
            case 'number':
              promises.push(this.getNumbers(t));
              break;
          }
        });

        await Promise.all(promises);

        this.loadingFields = false;
      },
      async getNumbers(types) {
        const payload = {
          processIds: this.isMultiple ? this.data.processIds : [this.data.processId],
          groupIds: this.isMultiple ? this.selectedGroup.map(g => g.id) : [this.selectedGroup.id],
          structureIds: this.isMultiple
            ? this.selectedStructure.length > 0
              ? this.selectedStructure.map(s => s.id)
              : this.structures.map(s => s.id)
            : this.selectedStructure
              ? [this.selectedStructure.id]
              : this.structures.map(s => s.id),
          structureTypes: types
        };

        try {
          const { data } = await ProcessService.fetchDataToSelect(payload);

          const newNestedValues = data.map(item => ({
            ...item,
            value: this.translate(item.name)
          }));

          this.nestedValues = [...newNestedValues];
        } catch (error) {
          console.error("Error fetching data to select:", error);
        }
      },
      async getStructures(types) {
        const payload = {
          processIds: this.isMultiple ? this.data.processIds : [this.data.processId],
          groupIds: this.isMultiple ? this.selectedGroup.map(g => g.id) : [this.selectedGroup.id],
          structureTypes: types
        };

        try {
          const { data } = await ProcessService.fetchStructures(payload);

          this.structures = data.map(v => ({
            ...v,
            value: this.translate(v.name)
          }));

          this.nestedValues = [];
          this.dataToCheck = [];
        } catch (error) {
          console.error("Error fetching structures:", error);
        }
      },
    }
  };
</script>

<style scoped>
</style>
