import { format, parseISO, startOfDay, startOfWeek, startOfMonth } from 'date-fns';
import { es, pt, enUS } from 'date-fns/locale';
import { DateRangeMixin } from "./date-range.mixin";

export const DataGroupingMixin = {
  mixins: [DateRangeMixin],
  methods: {
    groupDataByInterval(data, rangeType, rangeDateValue) {
      // Validar entrada de datos
      if (!data || !data.keys || !data.values) {
        console.warn('Invalid data structure provided to groupDataByInterval');
        return {
          keys: [],
          values: [],
          formattedKeys: [],
          groupingType: 'day'
        };
      }

      // Asegurar que rangeType tenga un valor válido
      rangeType = (rangeType || 'DAY').toUpperCase();
      rangeDateValue = parseInt(rangeDateValue) || 1;
      
      // Obtener límites del DateRangeMixin
      const maxValue = this.getMaxValueForRange(rangeType);
      
      // Determinar el tipo de agrupamiento basado en los límites establecidos
      let groupInterval;
      if (rangeType === 'DAY' && rangeDateValue > 90) {
        groupInterval = 'week';
      } else if (rangeType === 'WEEK' && rangeDateValue > 52) {
        groupInterval = 'month';
      } else if (rangeType === 'MONTH' && rangeDateValue > 24) {
        groupInterval = 'quarter';
      } else if (rangeType === 'YEAR' && rangeDateValue > 5) {
        groupInterval = 'year';
      }

      // Si no necesita agrupamiento, retornar datos originales con formato
      if (!groupInterval) {
        return {
          ...data,
          formattedKeys: data.keys.map(date => 
            this.getFormattedDate(date, rangeType.toLowerCase(), this.$i18n?.locale)
          ),
          groupingType: rangeType.toLowerCase()
        };
      }

      // Agrupar datos
      const groupedData = this.aggregateDataByInterval(data, groupInterval);
      
      return {
        ...groupedData,
        groupingType: groupInterval
      };
    },

    getTickAmount(totalPoints, rangeType) {
      // Determinar cantidad de ticks basado en el tipo de rango y total de puntos
      const baseTickAmount = {
        DAY: Math.min(90, Math.ceil(totalPoints / 7)),
        WEEK: Math.min(52, Math.ceil(totalPoints / 4)),
        MONTH: Math.min(24, Math.ceil(totalPoints / 2)),
        YEAR: Math.min(5, totalPoints)
      }[rangeType] || totalPoints;

      return Math.min(baseTickAmount, totalPoints);
    },

    calculateOptimalTickAmount(containerWidth, totalPoints, avgLabelLength) {
      // Ancho aproximado que necesita cada etiqueta (incluyendo espacio)
      const labelWidth = avgLabelLength * 8; // 8px por carácter aproximadamente
      const rotatedLabelWidth = labelWidth * 0.7; // Con rotación de -45°
      
      // Calcular cuántas etiquetas caben en el contenedor
      const optimalTicks = Math.floor(containerWidth / rotatedLabelWidth);
      
      // Si hay menos puntos que el óptimo, mostrar todos
      if (totalPoints <= optimalTicks) {
        return undefined; // undefined hace que ApexCharts muestre todas las etiquetas
      }
      
      return Math.min(optimalTicks, totalPoints);
    },

    normalizeDate(dateStr) {
      try {
        // Si la fecha no tiene segundos o Z, añadirlos
        if (dateStr.length === 16) { // formato "2025-02-09T23:59"
          dateStr = dateStr + ':00Z';
        } else if (!dateStr.endsWith('Z')) {
          dateStr = dateStr + 'Z';
        }
        return parseISO(dateStr);
      } catch (error) {
        console.warn('Error parsing date:', dateStr, error);
        // Intentar parse alternativo
        return parse(dateStr, 'yyyy-MM-dd\'T\'HH:mm', new Date());
      }
    },

    getFormattedDate(dateStr, groupingType = 'day', userLocale = 'pt') {
      if (!dateStr) return '';
      
      try {
        const date = this.normalizeDate(dateStr);
        const locales = {
          pt: pt,
          es: es,
          en: enUS
        };
        const locale = locales[userLocale] || pt;

        // Patrones de formato según el tipo de agrupación
        const formatPatterns = {
          DAY: "dd MMM",
          WEEK: "dd MMM", // Siempre mostrará el domingo
          MONTH: "MMM",
          YEAR: "yyyy"
        };

        if (groupingType.toUpperCase() === 'WEEK') {
          // Para semanas, siempre usar el domingo como inicio
          const weekStart = startOfWeek(date, { locale, weekStartsOn: 0 }); // 0 = domingo
          return format(weekStart, formatPatterns.WEEK, { locale });
        }

        return format(date, formatPatterns[groupingType.toUpperCase()] || formatPatterns.DAY, { locale });
      } catch (error) {
        console.warn('Error formatting date:', error);
        return dateStr;
      }
    },

    aggregateDataByInterval(data, interval) {
      const groups = new Map();
      
      data.keys.forEach((dateStr, index) => {
        const date = this.normalizeDate(dateStr);
        let groupKey;

        switch(interval) {
          case 'quarter':
            const quarter = Math.floor(date.getMonth() / 3);
            groupKey = new Date(date.getFullYear(), quarter * 3, 1);
            break;
          case 'bimonth':
            const bimonth = Math.floor(date.getMonth() / 2);
            groupKey = new Date(date.getFullYear(), bimonth * 2, 1);
            break;
          case 'month':
            groupKey = startOfMonth(date);
            break;
          case 'biweek':
            const week = Math.floor(date.getDate() / 14);
            groupKey = startOfWeek(new Date(date.getFullYear(), date.getMonth(), week * 14));
            break;
          case 'week':
            groupKey = startOfWeek(date);
            break;
          case '3days':
            const dayOfYear = Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
            groupKey = startOfDay(new Date(date.getFullYear(), 0, dayOfYear - (dayOfYear % 3)));
            break;
          default:
            groupKey = startOfDay(date);
        }

        const keyStr = format(groupKey, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        if (!groups.has(keyStr)) {
          groups.set(keyStr, {
            sum: 0,
            count: 0
          });
        }

        const group = groups.get(keyStr);
        group.sum += data.values[index] || 0;
        group.count += 1;
      });

      // Filtrar grupos con suma cero si hay suficientes valores no cero
      const filteredGroups = Array.from(groups.entries())
        .filter(([_, { sum }]) => sum > 0 || groups.size < 20)
        .sort(([a], [b]) => a.localeCompare(b));
      
      return {
        keys: filteredGroups.map(([date]) => date),
        values: filteredGroups.map(([_, { sum, count }]) => Math.round(sum / count)),
        formattedKeys: filteredGroups.map(([date]) => 
          this.getFormattedDate(date, interval, this.$i18n.locale)
        )
      };
    }
  }
};
