import moment from "moment";
import {mapGetters} from "vuex";
import i18n from "@/plugins/i18n";

export const FilterWidgetMixin = {
    data () {
        return {
            showTooltip: false,
            recalculating: true
        }
    },
    computed: {
        ...mapGetters({
            layout: 'dashboard/LAYOUT',
        }),
        localFilters() {
            const profile = JSON.parse(localStorage.getItem('profile'))
            let localFilters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`))
            console.log('global >>>', localFilters)

            if (!localFilters) {
                localFilters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-${this.layout.id}`))
                console.log("local >>>", localFilters)
            }

            return localFilters;
        },
        filters: {
            get() {
                return this.$store.getters['dashboard/GET_FILTERS'];
            },
            set(val) {
                this.$store.commit('dashboard/SET_FILTERS', val);
            }
        },
        appliedFiltersCount() {
            const applicableFilters = this.item.applicableFilters || [];
            const appliedFilters = this.filterObjectByKeys(this.filters, applicableFilters);
            let count = 0;
            for (let key in appliedFilters) {
                if (appliedFilters.hasOwnProperty(key)) {
                    const value = appliedFilters[key];
                    if (value !== null && Array.isArray(value) && value.length > 0)
                        count++;
                }
            }
            return count;
        },
        appliedFiltersList() {
            const applicableFilters = this.item.applicableFilters || [];
            const appliedFilters = this.filterObjectByKeys(this.filters, applicableFilters);
            const appliedKeys = [];
            for (let key in appliedFilters) {
                if (appliedFilters.hasOwnProperty(key)) {
                    const value = appliedFilters[key];
                    if (value !== null && Array.isArray(value) && value.length > 0)
                        appliedKeys.push(i18n.t(`widgets.filters.${key}`));
                }
            }
            return appliedKeys.join('<br>');
        }
    },
    methods: {
        isFilterApplicable(key, globalFilterType, entityType) {
            if (globalFilterType && typeof globalFilterType === 'object' && globalFilterType.key) {
                globalFilterType = globalFilterType.key;
            }
        
            if (key === 'classificationTypes') {
                if (globalFilterType === 'PRIMARY' && entityType === 'Location') {
                    return true;
                }
                if (globalFilterType === 'SECONDARY' && entityType === 'StorageDevice') {
                    return true;
                }
                return false;
            }
            return true;
        },
        filterObjectByKeys(obj, keys) {
            if (!keys || !(keys.length > 0)) return {};
        
            const newObj = {};
            const entityType = this.item?.props?.filter?.entity;
            const globalFilterType = obj.locationType;

            console.log('filterObjectByKeys', obj, keys, entityType, globalFilterType);
        
            keys.forEach(key => {
                // Skip classificationTypes if not applicable for this entity+locationType combination
                if (key === 'classificationTypes' && entityType) {
                    if (!this.isFilterApplicable(key, globalFilterType, entityType)) {
                        return; // Skip this filter
                    }
                }
        
                if (['date'].includes(key)) {
                    if (obj.dates && obj.dates.length > 0 )
                        newObj.dates = [
                            moment(obj.dates[0]).format('YYYY-MM-DDT00:00:00'),
                            moment(obj.dates[1]).format('YYYY-MM-DDT23:59:59')
                        ];
                }
                else if (['recordDates'].includes(key) ) {
                    if (obj.recordDates && obj.recordDates.length > 0)
                        newObj.recordDates = [
                            moment(obj.recordDates[0]).format('YYYY-MM-DDT00:00:00'),
                            moment(obj.recordDates[1]).format('YYYY-MM-DDT23:59:59')
                        ];
                } else if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (value !== null && !(Array.isArray(value) && value.length === 0)) {
                        newObj[key] = value;
                    }
                }
            });
        
            this.recalculating = false;
        
            return newObj;
        },
    },
};
