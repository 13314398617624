<template>
  <TableWrapper
    :tableConfig="tableConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    @reload:requested="reloadData"
    :item="item"
  >
    <template #dialog>
      <QrTopVisitDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>
    <template #values>
      <RenderValues :applicable-filters="item.applicableFilters" ref="renderValues" :widget-id="item.props.id" :t-color="tableConfig.color" :initialized="item.initialized" :headers="headers"/>
    </template>
  </TableWrapper>
</template>

<script>
import ChartWrapper from "@/module/dashboard/components/widgets/chart/common/ChartWrapper.vue";
import TableWrapper from "@/module/dashboard/components/widgets/table/common/TableWrapper.vue";
import QrTopVisitDialog from "@/module/dashboard/components/widgets/table/qr_top_visit/QrTopVisitDialog.vue";
import RenderValues from "@dashboard/components/widgets/table/common/RenderValues.vue";
import {TableWidgetMixin} from "@/module/dashboard/mixins/table-widget.mixin";
import {ReloadWidgetMixin} from "@/module/dashboard/mixins/reload-widget.mixin";
import i18n from "@/plugins/i18n";
import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";

export default {
  components: {ShowFilteredData, ChartWrapper, TableWrapper, RenderValues, QrTopVisitDialog},
  mixins: [TableWidgetMixin, ReloadWidgetMixin],
  computed: {
    headers: () => [
      {
        text: i18n.t("widgets.headers.product"),
        value: "product",
        type: "text",
        sortable: false,
      },
      {
        text: i18n.t("widgets.headers.lot"),
        value: "lot",
        type: "text",
        sortable: false,
      },
      {
        text: i18n.t("widgets.headers.visits"),
        value: "visits",
        type: "text",
        sortable: false,
      }
    ],
  }
}
</script>

<style scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

.data-table-card {
  border-radius: 8px;
  overflow: hidden;
}

.title-text {
  font-weight: bold;
  margin-left: 8px;
  color: #2c3e50; /* Dark text for contrast */
}

.search-input {
  max-width: 300px;
}
</style>
