import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n"
import _ from "lodash";

export const FormWidgetMixin = {
    computed: {
        ...mapGetters({
            requireRules: 'general/requireRules',
            selectRule: 'general/selectRule',
            requireAndCompareMax: 'general/requireAndCompareMax',
            requireAndMinMax: 'general/requireAndMinMax',
            minMax: 'general/minMax',
            onlyAllowInteger: 'general/onlyAllowInteger',
            arraySelectionRules: 'general/arraySelectionRules',
        }),
        regions() {
            return [
                {
                    name: i18n.t("common.language.es"),
                    value: "es-es",
                },
                {
                    name: i18n.t("common.language.pt"),
                    value: "pt-br",
                },
                {
                    name: i18n.t("common.language.en"),
                    value: "en-us",
                },
            ]
        },
    },
    methods: {
        async loadDependenciesByCard(type, subtype, ...args) {
            // console.log(args)
            // switch (type) {
            //     case 'CARD':
            //         switch (subtype) {
            //             case 'COUNT':
            //                 if (args[0] === 'StorageDevice')
            //                     await this.$store.dispatch('storage/fetchClassificationTypes');
            //         }
            // }
        },
        getByProperty(input, property) {
            const array = Array.isArray(input) ? input : [input];
            const allAreObjectsWithProperty = array.every(item =>
                typeof item === 'object' && item !== null && Object.hasOwnProperty.call(item, property)
            );

            if (allAreObjectsWithProperty) {
                const result = _.map(array, property);
                return Array.isArray(input) ? result : result[0];
            } else {
                return Array.isArray(input) ? array : input;
            }
        }
    }
};
