<template>
  <div class="fill-height">
    <v-row class="ma-0 pa-0 d-flex justify-space-between">
      <v-col cols="4" class="ma-0 pa-2" align-self="start" justify="start">
        <v-text-field
          v-if="!isEditingEnabled"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('widgets.search')"
          dense
          outlined
          background-color="#EAEAEA80"
          @keypress.enter="fetchData()"
          @click:clear="fetchData()"
          @click:append="fetchData()"
          color="primary"
          :clearable="false"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card elevation="0" class="ma-0 pa-0 fill-height overflow-hidden d-flex justify-space-between full-width">
      <LoadingWidgetData :loading="loading" />
      <EmptyWidgetData :is-empty="!isPresent && !loading" />
      <v-data-table
        v-bind="tableProps"
        :headers="headers"
        :items="values"
        :items-per-page="5"
        class="elevation-0 overflow-y-auto full-width"
        :loading="loading"
        :no-results-text="$t('common.ui.not_search_found')"
        :no-data-text="$t('common.ui.not_data_found')"
        :loading-text="$t('common.notification.loadData')"
        :footer-props="{
            itemsPerPageText: $t('common.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
          }"
      />
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LanguajeService from "@/services/LanguajeService";
import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin";
import EmptyWidgetData from "@/module/dashboard/components/common/EmptyWidgetData.vue";
import LoadingWidgetData from "@/module/dashboard/components/common/LoadingWidgetData.vue";

export default {
  name: "RenderValues",
  components: {LoadingWidgetData, EmptyWidgetData},
  mixins: [FilterWidgetMixin],
  props: {
    initialized: {
      type: Boolean,
      default: false
    },
    tColor: {
      type: String,
      default: '#232323'
    },
    widgetId: {
      type: String,
      required: true
    },
    ready: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: true
    },
    applicableFilters: {
      type: Array,
      required: false,
      default: () => ([])
    },
    tableProps: {
      type: Object,
      required: false,
      default: () => ({
        
      })
    }
  },
  data() {
    return {
      isPresent: false,
      values: [],
      loading: false,
      search: ''
    };
  },
  // async created() {
  //   await this.fetchData(true);
  // },
  mounted() {
    this.$root.$on('apply:dashboard-filters', (checkOnCreate = false) => {
      this.fetchData(checkOnCreate);
    })
    this.$root.$on('on:recalculate', () => {
      this.recalculating = true;
      this.filterObjectByKeys(this.filters, this.applicableFilters);
    })
  },
  computed: {
    ...mapGetters({
      layout: 'dashboard/LAYOUT'
    }),
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    async fetchData(checkOnCreated) {
      this.loading = true;
      if (!this.isEditingEnabled) {
        if (checkOnCreated || this.initialized) {
          const filters = this.filterObjectByKeys(this.filters, this.applicableFilters);
          await this.$store.dispatch('dashboard/fetchWidgetConfig', [this.widgetId, {
            ...filters,
            stringFilter: this.search
          }])
            .then(res => {
              if (res.reload) {
                this.$root.$emit('reload:layout', { reload: true, layoutId: this.layout.id });
              }

              this.values = res.values;
              this.isPresent = res.values.length > 0;
            })
            .catch(err => {
              console.log(err)
              this.isPresent = false;
            })
            .finally(() =>  this.loading = false)
        } else {
          this.loading = false;
        }
      } else {
        this.isPresent = false
        this.loading = false
      }
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key)
    },
  },

  beforeDestroy() {
    this.$root.$off('apply:dashboard-filters')
    this.$root.$off('on:recalculate')
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}
</style>