<template>
  <v-card class="data-map-card" flat style="height: 100%">
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="cardConfig.color">{{ cardConfig.icon }}</v-icon>
      <span class="title-text">{{ cardConfig.title }}</span>
      <v-spacer />
      <v-btn v-if="preview" @click="preview = !preview" class="my-2 px-5 t-bw-primary--text" color="primary" elevation="0" small><span class="font-weight-bold"><v-icon color="t-bw-primary--text" left>mdi-chevron-left</v-icon>{{ $t("common.buttons.back")}}</span></v-btn>
      <MapDialog :item="item" @update:layers="updateLayers()" @update:config="updateConfig($event)" @reload:map="loadMapComplete" />
    </v-card-title>
    <v-card-text style="height: calc(100% - 72px); border-radius: 8px;">
      <v-container fluid style="height: 100%" class="ma-0 pa-0">
        <v-row class="fill-height">
          <v-col class="fill-height pa-0">
            <span
              class="d-flex justify-center align-center align-content-center fill-height"
              v-if="isEditingEnabled && !preview"
            >
              <v-card
                height="250px"
                max-height="100%"
                width="100%"
                class="
                  shadow-none
                  ma-0
                  pa-0
                  my-4
                  elevation-0
                  d-flex
                  flex-column
                  justify-center
                  align-content-center
                  align-center
                  fill-height
                "
                elevation="0"
              >
                 <span>
                   <v-chip not-apply-styles small class="d-block text-center">
                    <v-icon small>mdi-alert-outline</v-icon>
                    <p
                      style="font-size: 12px; font-weight: normal"
                      class="ma-0 pa-0 mx-4"
                    >
                      {{ $t('common.ui.not_data_found') }}
                    </p>
                  </v-chip>
                  <v-btn @click="preview = !preview" class="my-2 px-5" color="primary t-bw-primary--text" elevation="0" small><span class="font-weight-bold">{{$t('widgets.preview')}}<v-icon color="t-bw-primary" right>mdi-cellphone-link</v-icon></span></v-btn>
                 </span>
              </v-card>
            </span>
            <span v-else>
              <v-container fluid class="ma-0 pa-0 fill-height">
                <v-card elevation="0" class="shadow-none ma-0 pa-0" height="100%" width="100%">
                  <map-box
                    v-show="!loading"
                    :displayDrawControlsDefault="false"
                    :drawControl="true"
                    :mapConfiguration="mapConfig"
                    :oneLocation="true"
                    :scaleControl="true"
                    :actionStyle="true"
                    :actionColor="false"
                    ref="mapMonitor"
                    :geolocateControl="true"
                    :fullScreenControl="true"
                    v-on:loadComplete="loadMapComplete"
                    @on:loaded="loading=$event"
                    mapHeight="auto"
                    :confStyle="'satellite-v9'"
                    v-on:styleLoad="setStyle"
                    v-on:filterDash="filterMap"
                    :stages="stages"
                    :layers="layers"
                  />
                  <v-overlay v-if="loading" :absolute="true" color="white" :opacity="1"> <v-progress-circular color="primary" indeterminate width="4" size="64"></v-progress-circular> </v-overlay>
                </v-card>
              </v-container>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import * as turf from '@turf/turf'
import { mapGetters } from "vuex"
import { CardWidgetMixin } from "@dashboard/mixins/card-widget.mixin"
import { ReloadWidgetMixin } from "@dashboard/mixins/reload-widget.mixin"
import MapService from "@/services/MapService";
import {convertLatitude, convertLongitude, isValidLatLong} from "../../../../../../../utils/convert-to-DMS-format";
import MapBox from "@/components/vueMapbox/MapBox.vue"
import MapDialog from "@/module/dashboard/components/widgets/statics/map/MapDialog.vue";

  export default {
    components: {MapDialog, MapBox },
    mixins: [CardWidgetMixin, ReloadWidgetMixin],
    data() {
      return {
        profile: null,
        stages: null,
        preview: false,
        layers: [
          { id: "l_locations_clusters", checked: true },
          { id: "l_locations_clusters_count", checked: true },
          { id: "l_locations_clusters_points", checked: false },
          { id: "l_locations_clusters_points_icon", checked: true },

          { id: "l_locations_names", checked: true },

          { id: "l_locations_center_points", checked: false },
          { id: "l_locations_center_points_icon", checked: true },

          { id: "l_locations_up_zoom", checked: true },
          { id: "l_locations_down_zoom", checked: false },


          { id: "l_storages_clusters", checked: true },
          { id: "l_storages_clusters_count", checked: true },
          { id: "l_storages_names", checked: true },
          { id: "l_storages_up_zoom", checked: true },
          { id: "l_storages_down_zoom", checked: false },
          { id: "l_storages_center_points", checked: false },
          { id: "l_storages_center_points_icon", checked: true },
          { id: "l_storages_clusters_points", checked: false },
          { id: "l_storages_clusters_points_icon", checked: true },
        ],
        mapFilters: [],
        loadingMap: true,
        locations_filters: [],
        storageDevicesFilters: [],
        loadings: {
          origin: false,
          destination: false
        },
        loading: true
      }
    },
    created() {
      this.profile = JSON.parse(localStorage.getItem("profile"))

      this.updateLayers()
    },

    computed: {
      ...mapGetters({
        storages: 'authority/authoritiesStoragesDevices',
        locationsList: 'authority/authoritiesLocations',
        storageDeviceTypesByCompany: "storage_type/storageDeviceTypesActiveByCompany"
      }),
      mapConfig: {
        get() {
          return this.$store.getters["general/mapConfig"]
        },
        set(val) {
          this.$store.commit("general/SET_MAP_CONFIG", val)
        },
      },
      isEditingEnabled() {
        return this.$store.getters["dashboard/IS_EDITING_MODE"]
      },
    },
    methods: {
      updateLayers() {
        this.loading = true;
        if (Object.keys(this.cardConfig.filter).includes('nestedValues') && this.cardConfig.filter.nestedValues.length > 0) {
          // console.log(this.cardConfig.filter.nestedValues)
          // const layers = {...this.layers}
          // let newLayers = [];
          // _.forEach(this.cardConfig.filter.nestedValues, (l)=>{
          //   const layer = _.find(layers, {id: l})
          //
          //   if (layer) {
          //     newLayers.push({...layer, checked: true})
          //   } else {
          //     newLayers.push({...layer, checked: false})
          //   }
          // })
          _.forEach(this.layers, (l, i) => {
            l.checked = this.cardConfig.filter.nestedValues.includes(l.id)
          })
        }
        this.loading = false;
      },
      // MAP
      async loadMapComplete() {
        console.log("mapLoadComplete")
        // this.$refs.mapMonitor.map.resize();
        await this.reload()
        this.$refs.mapMonitor.map.resize();

        // Force update map layers for center point zoom
        setTimeout(async () => {
          if (this.$refs.mapMonitor) {
            const featureLocations = await this.getDataLocations()
            const featureStorages = await this.getDataStorages()
            let toZoom = null;

            if(featureLocations.allFeatures.features.length <= 0 ){
              toZoom = featureStorages.allFeatures
            } else {
              toZoom = featureLocations.allFeatures
            }

            this.$refs.mapMonitor.map.setMaxZoom(8)
            this.$refs.mapMonitor.zoomToFitBounds(toZoom, 100)
            this.$refs.mapMonitor.map.setMaxZoom(20)
          }
        }, 1000)
      },
      setStyle() {
        this.reload()
      },
      async reload() {
        this.stages = Object.assign({}, this.storageDeviceTypesByCompany)
        await this.addLocationOnMap()
        this.loadingMap = false
      },
      async filterMap(filterStages) {
        this.stages = filterStages
        // Get Data and config paint layer locations
        const featureLocations = await this.getDataLocations()
        // Get Data and config paint layer storages
        const featureStorages = await this.getDataStorages()

        this.updateSources(featureLocations, featureStorages)

      },
      async addLocationOnMap() {
        // Delete map config
        this.$refs.mapMonitor.deleteMapConfig()

        // Get Data and config paint layer storages
        const featureStorages = await this.getDataStorages()
        // Get Data and config paint layer locations
        const featureLocations = await this.getDataLocations()

        // Set config sources and layers
        this.$refs.mapMonitor.setMapConfig()

        this.updateSources(featureLocations, featureStorages)

        this.addEventsMap()
      },
      updateSources(featureLocations, featureStorages){
        let toZoom = null;

        if(featureLocations.allFeatures.features.length <= 0 ){
          console.log('only storages')
          toZoom = featureStorages.allFeatures
          _.map(this.mapConfig.layers, (l, i) => {
            if((l.source === 's_storages_clusters' || l.source === 's_storages') && l.minzoom === 6){
              l.minzoom = 2
            }
          })
        } else {
          toZoom = featureLocations.allFeatures
        }

        if (featureLocations){
          this.$refs.mapMonitor.updateSource(
            "s_locations_clusters",
            featureLocations.allCenterPointsFeature
          )
          this.$refs.mapMonitor.updateSource(
            "s_locations",
            featureLocations.allFeatures
          )
        }
       if (featureStorages){
         this.$refs.mapMonitor.updateSource(
           "s_storages_clusters",
           featureStorages.allCenterPointsFeature
         )
         this.$refs.mapMonitor.updateSource(
           "s_storages",
           featureStorages.allFeatures
         )
       }

        this.$refs.mapMonitor.map.setMaxZoom(8)
        this.$refs.mapMonitor.zoomToFitBounds(toZoom, 100)
        this.$refs.mapMonitor.map.setMaxZoom(20)
      },
      async getDataStorages() {
        let storageFeaturesBySDT = {
          type: "FeatureCollection",
          features: [],
        };
        let allCenterPointsFeature = {
          type: 'FeatureCollection',
          features: []
        };
        let location = null;
        let locationJson = null;
        const mergeFormat = await MapService.mergeLocationsFormats(this.storages);

        for (const locationItem of mergeFormat) {
          const sdt = _.find(this.stages, {
            storage_device_type_id: parseInt(locationItem.storageDeviceType.id),
            checked: true,
          });
          if (
            this.IsJsonString(locationItem.location) &&
            locationItem.location &&
            locationItem.location !== "null" &&
            sdt
          ) {
            locationJson = JSON.parse(locationItem.location);
            if (
              locationJson &&
              locationJson.features &&
              locationJson.features.length > 0
            ) {
              location = locationJson.features[0];
              if (!location.properties) {
                location.properties = {}
              }
              location.properties.id = locationItem.storageDeviceId;
              location.properties.storageName = locationItem.control_label;
              location.properties.gln = locationItem.gln;
              location.properties.locationName = locationItem.control_label;
              location.properties.primaryName = locationItem.primaryLocation.name;
              location.properties.telephone = locationItem.primaryLocation.telephone;
              location.properties.email = locationItem.primaryLocation.email;
              location.properties.area = locationItem.area;
              location.properties.order = 1;

              if (isValidLatLong(locationItem.latitude, true) && isValidLatLong(locationItem.longitude, false)) {
                location.properties.lat = convertLatitude(locationItem.latitude);
                location.properties.long = convertLongitude(locationItem.longitude);
              }
              location.properties.address = this.setAddressPopUpData(locationItem);
              location.properties.locationsNames = this.setStoragePopUpData(locationItem);

              storageFeaturesBySDT.features.push(location);
              let point = turf.center(location);
              point.properties = location.properties;
              allCenterPointsFeature.features.push(point);

            }
          }
        }
        return {
          'allFeatures': storageFeaturesBySDT,
          "allCenterPointsFeature": allCenterPointsFeature
        };
      },
      async getDataLocations() {
        let locationsFeaturesBySDT = {
          type: "FeatureCollection",
          features: []
        }
        let allCenterPointsFeature = {
          type: 'FeatureCollection',
          features: []
        };

        let location = null;
        let locationJson = null;
        const mergeFormat = await MapService.mergeLocationsFormats(this.locationsList);

        for (const locationItem of mergeFormat) {
          const sdt = _.find(this.stages, {
            storage_device_type_id: parseInt(locationItem.storage_device_type_id),
            checked: true,
          });
          if (
            this.IsJsonString(locationItem.location) &&
            locationItem.location &&
            locationItem.location !== "null" &&
            sdt
          ) {
            locationJson = JSON.parse(locationItem.location);
            if (
              locationJson &&
              locationJson.features &&
              locationJson.features.length > 0
            ) {
              location = locationJson.features[0];
              if(!location.properties){
                location.properties = {}
              }
              location.properties.id = locationItem.locationId;
              location.properties.gln = locationItem.gln;
              location.properties.locationName = locationItem.name;
              location.properties.telephone = locationItem.telephone;
              location.properties.email = locationItem.email;
              location.properties.area = locationItem.area;
              location.properties.order = 2;


              if (isValidLatLong(locationItem.latitude, true) && isValidLatLong(locationItem.longitude, false)) {
                location.properties.lat = convertLatitude(locationItem.latitude);
                location.properties.long = convertLongitude(locationItem.longitude);
              }
              location.properties.address = this.setAddressPopUpData(locationItem);
              location.properties.locationsNames = locationItem.name;

              locationsFeaturesBySDT.features.push(location);
              let point = turf.center(location);
              point.properties = location.properties;
              allCenterPointsFeature.features.push(point);
            }
          }
        }
        return {
          "allFeatures": locationsFeaturesBySDT,
          "allCenterPointsFeature": allCenterPointsFeature
        };

      },

      setAddressPopUpData(locationItem) {
        return this.checkIfExist(
            locationItem.address1,
            true,
            ', ',
          ) +
          this.checkIfExist(
            locationItem.city,
            true,
            ', ',
          ) +
          this.checkIfExist(
            locationItem.state,
            true,
            ', ',
          ) +
          this.checkIfExist(locationItem.country, false)
      },
      setStoragePopUpData(locationItem) {
        return this.checkIfExist(
            locationItem.primaryLocation.name,
            true,
            ' | ',
          ) +
          this.checkIfExist(
            locationItem.control_label,
            false
          )
      },

      checkIfExist(target, continueTrue, separator) {
        target = target ? target : ''
        continueTrue = continueTrue && target !== '' ? separator : ''
        return target + continueTrue
      },
      addEventsMap() {
        this.$refs.mapMonitor.onMouseClickClusterZoomLayer(
          "l_locations_clusters","s_locations_clusters"
        );
        this.$refs.mapMonitor.onMouseClickClusterZoomLayer(
          "l_storages_clusters","s_storages_clusters"
        )


        this.$refs.mapMonitor.onMouseHoverPopUp(
          [
            "l_storages_clusters_points_icon",
            "l_storages_clusters_points",
            "l_storages_center_points_icon",
            "l_storages_center_points",
            "l_locations_clusters_points_icon",
            "l_locations_clusters_points",
            "l_locations_center_points_icon",
            "l_locations_center_points"
          ],
          "pointer","layers"
        )
        this.$refs.mapMonitor.onMouseLeavePopUp(
          [
            "l_storages_clusters_points_icon",
            "l_storages_clusters_points",
            "l_storages_center_points_icon",
            "l_storages_center_points",
            "l_locations_clusters_points_icon",
            "l_locations_clusters_points",
            "l_locations_center_points_icon",
            "l_locations_center_points"
          ]
        )

      },

      /**
       * @return {boolean}
       */
      IsJsonString(json) {
        try {
          JSON.parse(json);
        } catch (e) {
          return false;
        }
        return true;
      },
    },
  }
</script>

<style scoped>
  .v-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: auto !important;

    .v-card__text {
      padding-bottom: 0;
    }
  }

  .shadow-none {
    box-shadow: none !important;
  }

  .data-map-card {
    border-radius: 8px;
    overflow: hidden;
  }

  .title-text {
    font-weight: bold;
    margin-left: 8px;
    color: #2c3e50; /* Dark text for contrast */
  }

  .v-chip {
    border-radius: 0.3em;
  }

  :deep(.mgl-map-wrapper .mapboxgl-map) {
    border-radius: 8px;
  }
</style>
