<template>
  <div class="fill-height">
    <v-row class="ma-0 pa-0 d-flex justify-space-between">
      <v-col align-self="start" class="ma-0 pa-2" cols="4" justify="start">
        <v-text-field
          v-if="!isEditingEnabled"
          v-model="search"
          :clearable="false"
          :label="$t('widgets.search')"
          append-icon="mdi-magnify"
          background-color="#EAEAEA80"
          color="primary"
          dense
          hide-details
          outlined
          @keypress.enter="fetchByText()"
          @click:clear="fetchData()"
          @click:append="fetchByText()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="ma-0 pa-0 fill-height overflow-hidden d-flex justify-space-between full-width" elevation="0">
      <LoadingWidgetData :loading="loading" />
      <EmptyWidgetData :is-empty="!isPresent && !loading" />
      <v-data-table
        :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('common.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
          }"
        :headers="localHeaders"
        :items="values"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :loading-text="$t('common.notification.loadData')"
        :no-data-text="$t('common.ui.not_data_found')"
        :no-results-text="$t('common.ui.not_search_found')"
        :options.sync="options"
        :server-items-length="totalFromServer"
        :sort-by.sync="this.defaultSort"
        :sort-desc="(orderBy === 'DESC' || (orderBy && orderBy.key === 'DESC'))"
        class="elevation-0 overflow-y-auto full-width"
      />
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import LanguajeService from "@/services/LanguajeService"
  import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin"
  import LoadingWidgetData from "@/module/dashboard/components/common/LoadingWidgetData.vue"
  import EmptyWidgetData from "@/module/dashboard/components/common/EmptyWidgetData.vue"

  export default {
    components: { EmptyWidgetData, LoadingWidgetData },
    mixins: [FilterWidgetMixin],
    props: {
      initialized: {
        type: Boolean,
        default: false,
      },
      tColor: {
        type: String,
        default: "#232323",
      },
      widgetId: {
        type: String,
        required: true,
      },
      ready: {
        type: Boolean,
        default: false,
      },
      headers: {
        type: Array,
        required: true,
      },
      applicableFilters: {
        type: Array,
        required: false,
        default: () => [],
      },
      defaultSort: {
        type: String,
        required: true,
      },
      orderBy: {
        type: [String, Object],
        default: "DESC",
      },
    },
    data() {
      return {
        isPresent: false,
        values: [],
        loading: true,
        rowsPerPageItemsOptions: {
          itemsPerPageOptions: [5, 10, 15, 20],
        },
        localHeaders: [],
        search: "",
        totalFromServer: 0,
        options: {},
        itemsPerPage: 5,
      }
    },
    watch: {
      options: {
        async handler() {
          await this.fetchData()
        },
        deep: true,
      },
    },
    async created() {
      this.localHeaders = [...this.headers]
      await this.fetchData(true)
    },
    mounted() {
      this.$root.$on('apply:dashboard-filters', (checkOnCreate = false) => {
        this.fetchData(checkOnCreate);
      })
      this.$root.$on("on:recalculate", () => {
        this.recalculating = true
        this.filterObjectByKeys(this.filters, this.applicableFilters)
      })
    },
    computed: {
      ...mapGetters({
        layout: "dashboard/LAYOUT",
      }),
      isEditingEnabled() {
        return this.$store.getters["dashboard/IS_EDITING_MODE"]
      },
    },
    methods: {
      async fetchByText() {
        this.options.page = 1
        await this.fetchData()
      },
      async fetchData(checkOnCreated) {
        this.loading = true
        if (!this.isEditingEnabled) {
          if (checkOnCreated || this.initialized) {
            const filters = this.filterObjectByKeys(this.filters, this.applicableFilters)

            const sort = this.options.sortBy && this.options.sortBy.length > 0 ? this.options.sortBy[0] : this.defaultSort
            const direction = typeof this.orderBy === "object" && this.orderBy !== null ? this.orderBy.key : this.orderBy

            filters.pageableDTO = {
              page: this.options.page - 1,
              size: this.options.itemsPerPage,
              sortBy: sort,
              direction: direction,
            }

            await this.$store.dispatch("dashboard/fetchWidgetConfig", [this.widgetId, {
              stringFilter: this.search || "",
              ...filters,
            }])
              .then(res => {
                if (res.reload) {
                  this.$root.$emit("reload:layout", { reload: true, layoutId: this.layout.id })
                }

                if (res.content && res.content.length > 0 && res.content[0].keys) {
                  // Establecer localHeaders en base a los keys que llegan en res.content[0].keys
                  this.setLocalHeaders(res.content[0].keys)
                  this.values = res.content[0].values
                  this.totalFromServer = res.totalElements
                  this.isPresent = true
                } else {
                  this.isPresent = false
                }
              })
              .catch(err => {
                console.log(err)
                this.isPresent = false
              })
              .finally(() => this.loading = false)
          } else {
            this.loading = false
          }
        } else {
          this.isPresent = false
          this.loading = false
        }
      },

      // Método para establecer los headers locales
      setLocalHeaders(keys) {
        const orderedHeaders = this.headers.filter(header => keys.includes(header.value))
          .sort((a, b) => keys.indexOf(a.value) - keys.indexOf(b.value))

        this.localHeaders = orderedHeaders
      },

      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
    },

    beforeDestroy() {
      this.$root.$off("apply:dashboard-filters")
      this.$root.$off("on:recalculate")
    },
  }
</script>

<style scoped>
  .v-chip {
    border-radius: .3em;
  }
</style>
