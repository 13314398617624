import { render, staticRenderFns } from "./FormComponent.vue?vue&type=template&id=9686b732&scoped=true"
import script from "./FormComponent.vue?vue&type=script&lang=js"
export * from "./FormComponent.vue?vue&type=script&lang=js"
import style0 from "./FormComponent.vue?vue&type=style&index=0&id=9686b732&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9686b732",
  null
  
)

export default component.exports