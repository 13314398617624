<template>
  <ChartWrapper
    :chartConfig="chartConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    :ready="ready"
    @on:download="exportChart(chartConfig.title)"
    @reload:requested="reloadData"
    :item="item"
    :individualNavigation="item.props.filter.locationType === 'SECONDARY'"
  >
    <template #dialog>
      <LocationDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>

    <template #values>
      {{ item.porps }}
      <RenderValues
        @update:values="updateChart($event)"
        @on:ready="ready = $event"
        ref="renderValues"
        :chart-options="chartOptions"
        :chart-series="chartSeries"
        :ready="ready"
        :widget-id="item.props.id"
        :t-color="chartConfig.color"
        :initialized="item.initialized"
        :applicable-filters="item.applicableFilters"
      />
    </template>
  </ChartWrapper>
</template>

<script>
import ChartWrapper from "@/module/dashboard/components/widgets/chart/common/ChartWrapper.vue"
import LocationDialog from "@/module/dashboard/components/widgets/chart/bar/location/LocationDialog.vue";
import RenderValues from "@/module/dashboard/components/widgets/chart/common/RenderValues.vue";
import { ChartWidgetMixin } from "@/module/dashboard/mixins/chart-widget.mixin";
import { ReloadWidgetMixin } from "@/module/dashboard/mixins/reload-widget.mixin";
import { BarMixin } from "@/module/dashboard/components/widgets/chart/bar/mixins/bar.mixin";
import CardWrapper from "@/module/dashboard/components/widgets/card/common/CardWrapper.vue";

export default {
  components: {
    CardWrapper,
    ChartWrapper,
    RenderValues,
    LocationDialog
  },
  mixins: [ChartWidgetMixin, ReloadWidgetMixin, BarMixin],
  data() {
    return {
      items: [],
      locationType: null,
      locationPosition: null,
    };
  },
  created() {
    this.configureChartForInteractions();
  },
  methods: {
    configureChartForInteractions() {
      if (!this.chartOptions) return;

      if (this.chartOptions.chart) {
        this.chartOptions.chart.events = {
          ...this.chartOptions.chart.events,
          dataPointSelection: this.handleDataPointSelection
        };

        this.chartOptions.chart.selection = {
          enabled: true
        };

        this.chartOptions.states = {
          ...this.chartOptions.states,
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'lighten',
              value: 0.1
            }
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.15
            }
          }
        };

        if (!this.chartOptions.plotOptions) {
          this.chartOptions.plotOptions = {};
        }
        
        if (!this.chartOptions.plotOptions.bar) {
          this.chartOptions.plotOptions.bar = {};
        }
        
        this.chartOptions.plotOptions.bar.distributed = true;
      }
    },
    
    updateChart(e) {
      if (this.chartOptions && this.chartOptions.chart) {
        if (e.items) {
          console.log('Items recibidos:', e.items);
          this.items = e.items;
          
          if (!this.chartOptions.chartContext) {
            this.chartOptions.chartContext = {};
          }
          this.chartOptions.chartContext.items = e.items;
        }
        
        if (this.item && this.item.props && this.item.props.filter) {
          this.locationType = this.item.props.filter.locationType;
          this.locationPosition = this.item.props.filter.locationPosition;
          this.chartOptions.chartContext.locationType = this.locationType;
          this.chartOptions.chartContext.locationPosition = this.locationPosition;
          console.log('Tipo de ubicación:', this.locationType);
          console.log('Posición de ubicación:', this.locationPosition);
        }
        
        this.configureChartForInteractions();
      }
      
      this.chartOptions.xaxis.categories = e.xAxis;
      this.chartSeries[0].data = e.yAxis;
      this.$set(this, 'format', e.format);

      this.ready = true;
      this.$refs.renderValues.refreshChart();
    },
    
    handleDataPointSelection(event, chartContext, config) {
      if (config.dataPointIndex >= 0 && config.seriesIndex >= 0) {
        const index = config.dataPointIndex;
        const key = chartContext.w.globals.labels[index];
        const value = chartContext.w.globals.series[config.seriesIndex][index];
        
        const item = this.items && this.items.length > index ? this.items[index] : null;
        
        console.log('Bar seleccionada:', {
          index,
          key,
          value,
          item,
          locationType: this.locationType,
          locationPosition: this.locationPosition
        });
        
        if (this.locationType === 'SECONDARY' && item) {
          this.processLocationInteraction(item, key, value, index);
        }
      }
    },
    
    processLocationInteraction(item, key, value, index) {
      console.log('Procesando interacción para ubicación SECONDARY:', item);
      if (this.locationType === 'SECONDARY' && item) {
        this.applyLocationFiltersAndNavigate(item, key);
      }
    },
    
    async applyLocationFiltersAndNavigate(item, locationName) {
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'));
      if (!filtersRecords) {
        console.error('No se encontraron filtros de records en localStorage');
        return;
      }
      
      const profile = JSON.parse(localStorage.getItem('profile'));
      let globalFilters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-${this.$store.getters['dashboard/LAYOUT'].id}`));
      if (!globalFilters) {
        globalFilters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`));
      }
      
      if (globalFilters && globalFilters.dates && globalFilters.dates.length > 0) {
        filtersRecords.filtersRecordDate = globalFilters.dates;
        filtersRecords.filtersDate = `${globalFilters.dates[0]} ~ ${globalFilters.dates[1]}`;
      }
      
      if (globalFilters && globalFilters.recordDates && globalFilters.recordDates.length > 0) {
        filtersRecords.filtersRecordRegisterDate = globalFilters.recordDates;
        filtersRecords.filtersRegisterDate = `${globalFilters.recordDates[0]} ~ ${globalFilters.recordDates[1]}`;
      }
      
      if (this.item && this.item.props && this.item.props.filter && this.item.props.filter.processIds) {
        filtersRecords.filtersObjects.process_ids = [...this.item.props.filter.processIds];
      } else if (globalFilters && globalFilters.processes && globalFilters.processes.length > 0) {
        filtersRecords.filtersObjects.process_ids = [...globalFilters.processes];
      }
      
      if (item) {
        let primaryId = null;
        let secondaryId = null;
        
        if (item.primary !== undefined) primaryId = item.primary;
        if (item.secondary !== undefined) secondaryId = item.secondary;
        
        if (primaryId !== null && this.locationPosition === 'ORIGIN') {
          filtersRecords.origin_locations_filters = [primaryId];
          filtersRecords.filtersObjects.sdi_origin_ids = [secondaryId];
        }
        
        if (secondaryId !== null && this.locationPosition === 'DESTINATION') {
          filtersRecords.destination_locations_filters = [primaryId];
          filtersRecords.filtersObjects.sdi_destination_ids = [secondaryId];
        }
        
        filtersRecords.filtersObjects.locationStrict = true;
      } else {
        console.log('No se encontró el item para aplicar filtros de ubicación');
      }
      
      filtersRecords.filtersObjects.record.status = ['VALIDATED'];
      
      localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords));
      
      try {
        const processIds = filtersRecords.filtersObjects.process_ids || [];
        const stages = this.getProcessStages(processIds);
        
        if (stages && stages.length > 0) {
          await this.$router.push({ 
            name: 'records', 
            params: { stage: parseInt(stages[0], 10) }
          });
        } else {
          await this.$router.push({ name: 'records' });
        }
      } catch (error) {
        console.error('Error al navegar a la vista de records:', error);
      }
    },
    
    getProcessStages(processIds) {
      if (!processIds || processIds.length === 0) return [];
      
      const processes = this.$store.getters['authority/authoritiesProcess'] || [];
      
      const filteredProcesses = processes.filter(p => processIds.includes(p.id));
      
      const stageIds = filteredProcesses
        .filter(p => p.sdt_to_show && p.sdt_to_show.id)
        .map(p => p.sdt_to_show.id);
      
      return stageIds;
    }
  }
}
</script>