<!-- DrawerComponent.vue -->
<template>
  <div style="position: relative">
    <slot name="prepend"></slot>
    <slot name="append"></slot>

    <v-navigation-drawer :value="drawer" app right width="300px" style="z-index: 1000; max-height: 100% !important;">
      <v-card-title>
        {{$t('widgets.editMode.title')}}
        <v-spacer />
        <v-chip class="ma-0 px-2"  small label>
          {{$t('widgets.editMode.keyCombination1')}}
          &nbsp;
          <v-icon small class="shortcut-icon ma-0 pa-0 primary--text">mdi-apple-keyboard-shift</v-icon>
          + F &nbsp;
          {{$t('widgets.editMode.keyCombination2')}}
        </v-chip>
      </v-card-title>
      <v-divider></v-divider>
      <SearchInput class="ma-2" ref="searchInput" @on-search="filterWidgets" />
      <v-divider></v-divider>
      <!-- Overlay cuando no hay layout seleccionado -->

      <!-- Contenido normal del drawer -->
      <v-list dense style="overflow: auto; max-height: calc(100vh - 150px);">
        <v-list-item v-for="(widget, i) in filteredWidgets" :key="i">
          <v-list-item-content>
              <v-overlay :value="!isLayoutSelected" color="white" :opacity=".1">
                <v-chip class="pa-6 text-center t-bw-primary--text" color="primary" label>
                  {{$t('widgets.editMode.alert1')}}<br> {{$t('widgets.editMode.alert2')}}
                </v-chip>
              </v-overlay>
            <component :is="widget.component" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SearchInput from "@dashboard/components/common/SearchInput.vue";
import CountSkeleton from "@dashboard/components/widgets/card/count/CountSkeleton.vue";
import ImageSkeleton from "@dashboard/components/widgets/card/image/ImageSkeleton.vue";
import AverageSkeleton from "@dashboard/components/widgets/card/average/AverageSkeleton.vue";
import SumSkeleton from "@dashboard/components/widgets/card/sum/SumSkeleton.vue";
import SumByUnitSkeleton from "@dashboard/components/widgets/card/sum_by_unit/SumSkeleton.vue";
import FactorSkeleton from "@dashboard/components/widgets/card/factor/FactorSkeleton.vue";
import EntityTableSkeleton from "@dashboard/components/widgets/table/entity/EntitySkeleton.vue";
import QrTopVisitSkeleton from "@dashboard/components/widgets/table/qr_top_visit/QrTopVisitSkeleton.vue";
import QrLastVisitSkeleton from "@dashboard/components/widgets/chart/dots/qr_last_visit/QrLastVisitSkeleton.vue";
import RecordTableSkeleton from "@dashboard/components/widgets/statics/records/RecordSkeleton.vue";
import LocationChartSkeleton from "@dashboard/components/widgets/chart/bar/location/LocationSkeleton.vue";
import QrTopCitiesSkeleton from "@dashboard/components/widgets/chart/bar/qr_top_cities/QrTopCitiesSkeleton.vue";
import QrVisitCitiesSkeleton from "@dashboard/components/widgets/table/qr_visit_cities/QrVisitCitiesSkeleton.vue";
import EntityChartSkeleton from "@dashboard/components/widgets/chart/bar/entity/EntitySkeleton.vue";
import ProductPieChartSkeleton from "@dashboard/components/widgets/chart/pie/product/ProductSkeleton.vue";
import ProductDonutChartSkeleton from "@dashboard/components/widgets/chart/donut/product/ProductSkeleton.vue";
import DocumentCardSkeleton from "@dashboard/components/widgets/statics/documents/DocumentSkeleton.vue";
import MapCardSkeleton from "@dashboard/components/widgets/statics/map/MapSkeleton.vue";
import MapLocationCardSkeleton from "@dashboard/components/widgets/map/locations/LocationSkeleton.vue";
import {mapGetters} from "vuex";
import i18n from "@/plugins/i18n";
import ProducersByStateTableSkeleton
  from "@/module/dashboard/components/widgets/table/producers_by_state/ProducersByStateSkeleton.vue"
import ProductsByStateTableSkeleton
  from "@/module/dashboard/components/widgets/table/products_by_state/ProductsByStateSkeleton.vue"
import ProductsByStateChartSkeleton
  from "@/module/dashboard/components/widgets/chart/column/products_by_state/ProductsByStateSkeleton.vue"
import ProductsByLocationChartSkeleton
  from "@/module/dashboard/components/widgets/chart/bar/products_by_location/ProductsByLocationSkeleton.vue"
import LocationsByStateSkeleton
  from "@/module/dashboard/components/widgets/table/locations_by_state/LocationsByStateSkeleton.vue"
import BonusEstimateSkeleton from "@/module/dashboard/components/widgets/table/estimate/BonusEstimateSkeleton.vue"

export default {
  components: {
    SearchInput,
    CountSkeleton,
    ImageSkeleton,
    AverageSkeleton,
    SumSkeleton,
    SumByUnitSkeleton,
    FactorSkeleton,
    EntityTableSkeleton,
    QrTopVisitSkeleton,
    EntityChartSkeleton,
    LocationChartSkeleton,
    ProductPieChartSkeleton,
    ProductDonutChartSkeleton,
    DocumentCardSkeleton,
    RecordTableSkeleton,
    MapCardSkeleton,
    MapLocationCardSkeleton,
    QrLastVisitSkeleton,
    QrTopCitiesSkeleton,
    QrVisitCitiesSkeleton,
    ProducersByStateTableSkeleton,
    ProductsByStateTableSkeleton,
    ProductsByStateChartSkeleton,
    ProductsByLocationChartSkeleton,
    LocationsByStateSkeleton,
    BonusEstimateSkeleton
  },
  data: () => ({
    searchQuery: '',
    selectedCategory: 'all',
    widgets: [
      { name:i18n.t('widgets.names.COUNT'), category: 'card', component: CountSkeleton },
      { name:i18n.t('widgets.names.IMAGE'), category: 'card', component: ImageSkeleton },
      { name:i18n.t('widgets.names.STRUCTURE_AVERAGE'), category: 'card', component: AverageSkeleton },
      { name:i18n.t('widgets.names.AGTRACE_SUM'), category: 'card', component: SumSkeleton },
      { name:i18n.t('widgets.names.AGTRACE_SUM_BY_UNIT'), category: 'card', component: SumByUnitSkeleton },
      { name:i18n.t('widgets.names.CALC_WITH_FACTOR'), category: 'card', component: FactorSkeleton },
      { name:i18n.t('widgets.names.TOP_RECORDS'), category: 'table', component: RecordTableSkeleton },
      { name:i18n.t('widgets.names.TOP_TRACEABILITY_QR_VISIT'), category: 'table', component: QrTopVisitSkeleton },
      // { name: 'EntityTable', category: 'table', component: EntityTableSkeleton },
      { name:i18n.t('widgets.names.ESTIMATE'), category: 'table', component: BonusEstimateSkeleton },
      { name:i18n.t('widgets.names.CITY_QR_VISIT'), category: 'table', component: QrVisitCitiesSkeleton },
      { name:i18n.t('widgets.names.LOCATIONS_BY_STATE'), category: 'table', component: LocationsByStateSkeleton },
      { name:i18n.t('widgets.names.PRODUCERS_BY_STATE_LOCATION'), category: 'table', component: ProducersByStateTableSkeleton },
      { name:i18n.t('widgets.names.QUANTITY_PRODUCTS_BY_STATE_LOCATION'), category: 'table', component: ProductsByStateTableSkeleton },
      { name:i18n.t('widgets.names.QUANTITY_PRODUCTS_BY_STATE_LOCATION'), category: 'chart', component: ProductsByStateChartSkeleton },
      { name:i18n.t('widgets.names.QUANTITY_PRODUCTS_BY_LOCATION'), category: 'chart', component: ProductsByLocationChartSkeleton },
      { name:i18n.t('widgets.names.COUNT_PROCESS_BY_LOCATION'), category: 'chart', component: LocationChartSkeleton },
      { name:i18n.t('widgets.names.TOP_CITY_QR_VISIT'), category: 'chart', component: QrTopCitiesSkeleton },
      { name:i18n.t('widgets.names.LAST_QR_VISIT_BY_RANGE'), category: 'chart', component: QrLastVisitSkeleton },
      // { name: 'EntityChart', category: 'chart', component: EntityChartSkeleton },
      // { name: 'ProductPieChart', category: 'chart', component: ProductPieChartSkeleton },
      { name:i18n.t('widgets.names.AGTRACE_COUNT_SELECTED_PRODUCTS'), category: 'chart', component: ProductDonutChartSkeleton },
      { name:i18n.t('widgets.names.DOCUMENT_EXPIRY'), category: 'card', component: DocumentCardSkeleton },
      { name:i18n.t('widgets.names.MAP_BY_LAYERS'), category: 'card', component: MapCardSkeleton },
      { name:i18n.t('widgets.names.MAP_LOCATIONS'), category: 'map', component: MapLocationCardSkeleton },
    ]
  }),
  computed: {
    ...mapGetters({
      layout: 'dashboard/LAYOUT'
    }),
    drawer() {
      return this.$store.getters['dashboard/GET_WIDGET_DRAWER'];
    },
    isLayoutSelected() {
      return this.layout ? !!this.layout.name : false;
    },
    isEditingEnabled: {
      get() {
        return this.$store.getters['dashboard/IS_EDITING_MODE'];
      },
      set(val) {
        this.$store.dispatch('dashboard/toggleEditMode', val);
      }
    },
    filteredWidgets() {
      return this.widgets.filter(widget => {
        const matchesCategory = this.selectedCategory === 'all' || widget.category.toLowerCase().includes(this.selectedCategory.toLowerCase());
        const matchesQuery = !this.searchQuery || widget.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        return matchesCategory && matchesQuery;
      });
    }
  },
  methods: {
    filterWidgets({ query, category }) {
      this.searchQuery = query;
      this.selectedCategory = category;
    }
  }
};
</script>
