<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >

    <template v-slot:custom-fields>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12">
            <DateRangeSelector
              v-model="dateRangeConfig"
              :select-rule="selectRule"
              @input="handleDateRangeChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@dashboard/components/common/FormComponent.vue";
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";
import DateRangeSelector from "@/module/dashboard/components/common/DateRangeSelector.vue";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import { GlobalWidgetMixin } from "@/module/dashboard/mixins/global-widget.mixin";
import { DateRangeMixin } from "@/module/dashboard/mixins/date-range.mixin";
import i18n from "@/plugins/i18n";

export default {
  name: 'QrLastVisitDialog',
  components: {FormComponent, IconPicker, ColorPicker, DateRangeSelector},
  mixins: [GlobalWidgetMixin, FormWidgetMixin, DateRangeMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.LAST_QR_VISIT_BY_RANGE'),
        icon: 'mdi-chart-timeline-variant',
        color: '#232323',
        filter: {
          rangeDate: 'DAY',
          rangeDateValue: 90
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'LAST_QR_VISIT_BY_RANGE',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.LAST_QR_VISIT_BY_RANGE'),
        icon: 'mdi-chart-timeline-variant',
        color: '#232323',
        filter: {
          rangeDate: 'DAY',
          rangeDateValue: 90
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'LAST_QR_VISIT_BY_RANGE',
        type: 'CHART'
      },
      dateRangeConfig: {
        rangeDate: 'DAY',
        rangeDateValue: 90
      },
      rangeDates: [
        {
          key: 'DAY',
          name: i18n.t('widgets.dialogs.rangeDate.day')
        },{
          key: 'WEEK',
          name: i18n.t('widgets.dialogs.rangeDate.week')
        },{
          key: 'MONTH',
          name: i18n.t('widgets.dialogs.rangeDate.month')
        },{
          key: 'YEAR',
          name: i18n.t('widgets.dialogs.rangeDate.year')
        }
      ]
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "LAST_QR_VISIT_BY_RANGE",
      info: "LAST_QR_VISIT_BY_RANGE",
    };
  },
  methods: {
    handleRangeDateChange() {
      const maxValue = this.getMaxValueForRange(this.config.filter.rangeDate);
      if (this.config.filter.rangeDateValue > maxValue) {
        this.config.filter.rangeDateValue = maxValue;
      }
    },
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          ...data.filter,
          rangeDate: this.dateRangeConfig.rangeDate?.key || this.dateRangeConfig.rangeDate,
          rangeDateValue: parseInt(this.dateRangeConfig.rangeDateValue) || 90,
        },
        initialized: true
      };

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      this.config = { ...this.item.props };
      if (this.item.initialized) {
        this.config.filter.rangeDate = _.find(this.rangeDates, { key: this.config.filter.rangeDate.key || this.config.filter.rangeDate })
        this.config.filter.rangeDateValue = this.config.filter.rangeDateValue || 90;
        
        this.dateRangeConfig = {
          rangeDate: this.config.filter.rangeDate?.key || this.config.filter.rangeDate || 'DAY',
          rangeDateValue: parseInt(this.config.filter.rangeDateValue) || 90
        };
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    },
    handleDateRangeChange(config) {
      this.dateRangeConfig = {
        rangeDate: config.rangeDate,
        rangeDateValue: parseInt(config.rangeDateValue) || 90
      };
    }
  }
};
</script>

<style scoped>
</style>
