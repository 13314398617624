<template>
  <ChartWrapper
    :chartConfig="chartConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    :ready="ready"
    :download="false"
    @reload:requested="reloadData"
    :item="item"
    :link="false"
  >
    <template #dialog>
      <QrLastVisitDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>

    <template #values>
      <RenderValues
        @update:values="updateChart($event)"
        @on:ready="ready = $event"
        ref="renderValues"
        :chart-options="chartOptions"
        :chart-series="chartSeries"
        :ready="ready"
        :widget-id="item.props.id"
        :t-color="chartConfig.color"
        :initialized="item.initialized"
        :applicable-filters="item.applicableFilters"
      />
    </template>
  </ChartWrapper>
</template>

<script>
import ChartWrapper from "@/module/dashboard/components/widgets/chart/common/ChartWrapper.vue"
import QrLastVisitDialog from "@dashboard/components/widgets/chart/dots/qr_last_visit/QrLastVisitDialog.vue";
import RenderValues from "@dashboard/components/widgets/chart/common/RenderValues.vue";
import { ChartWidgetMixin } from "@dashboard/mixins/chart-widget.mixin";
import { ReloadWidgetMixin } from "@dashboard/mixins/reload-widget.mixin";
import { DotsMixin } from "@/module/dashboard/components/widgets/chart/dots/mixins/dots.mixin"

export default {
  components: {
    ChartWrapper,
    RenderValues,
    QrLastVisitDialog
  },
  mixins: [ChartWidgetMixin, ReloadWidgetMixin, DotsMixin]
}
</script>
