import LanguajeService from "@/services/LanguajeService";
import ProcessService from "@/module/dashboard/services/ProcessService";

export const ProcessStructureLoaderMixin = {
  data() {
    return {
      loading: {
        process: false,
        groups: false,
        structures: false,
        listStructures: false,
        numberStructures: false,
        nestedValues: false
      },
      processId: null,
      groupId: null,
      structureId: null,
      listStructureId: null,
      numberStructureId: null,
      groups: [],
      structures: [],
      listStructures: [],
      numberStructures: [],
      nestedValue: null,
      nestedValues: [],
      nestedValuesSearch: '',
      structuresSearch: '',
      pagination: {
        nestedValues: {
          currentPage: 1,
          itemsPerPage: 10,
        },
        structures: {
          currentPage: 1,
          itemsPerPage: 10,
        },
      },
      // Define the structure types for easier reuse
      listStructureTypes: ['LIST', 'LISTSIMPLE', 'PRODUCT', 'EQUIPMENT', 'INPUT', 'OPERATOR', 'PRODUCTSIMPLE', 'EQUIPMENTSIMPLE', 'OPERATORSIMPLE', 'INPUTSIMPLE'],
      numberStructureTypes: ['NUMBER']
    };
  },
  
  computed: {
    displayedStructures() {
      const start = (this.pagination.structures.currentPage - 1) * this.pagination.structures.itemsPerPage;
      const end = start + this.pagination.structures.itemsPerPage;
      if (!this.structures) this.structures = [];
      return this.structures.slice(start, end);
    },

    filteredStructures() {
      if (!this.structuresSearch) {
        return this.structures;
      }
      const lowerCaseSearch = this.structuresSearch.toLowerCase();
      return this.structures.filter(nested => nested.value.toLowerCase().includes(lowerCaseSearch));
    },
    
    displayedNestedValues() {
      const start = (this.pagination.nestedValues.currentPage - 1) * this.pagination.nestedValues.itemsPerPage;
      const end = start + this.pagination.nestedValues.itemsPerPage;
      return this.filteredNestedValues.slice(start, end);
    },

    filteredNestedValues() {
      if (!this.nestedValuesSearch) {
        return this.nestedValues;
      }
      const lowerCaseSearch = this.nestedValuesSearch.toLowerCase();
      return this.nestedValues.filter(nested => nested.value.toLowerCase().includes(lowerCaseSearch));
    },
  },
  
  watch: {
    // Add cascading clear watchers for the selectable fields
    processId(newVal) {
      if (!newVal) {
        // Clear all dependent fields when process is cleared
        this.clearDependentFields('process');
      }
    },
    
    groupId(newVal) {
      if (!newVal) {
        // Clear all dependent fields when group is cleared
        this.clearDependentFields('group');
      }
    },
    
    listStructureId(newVal) {
      if (!newVal) {
        // Clear nested value when list structure is cleared
        this.clearDependentFields('listStructure');
      }
    },
    
    numberStructureId(newVal) {
      if (!newVal) {
        // Clear any dependent fields of number structure (if applicable)
        this.clearDependentFields('numberStructure');
      }
    }
  },
  
  methods: {
    translate(key) {
      return LanguajeService.getKey3(key);
    },
    
    // Add new cascading clear method
    clearDependentFields(level) {
      switch(level) {
        case 'process':
          // When process is cleared, clear group and everything below
          this.groupId = null;
          this.groups = [];
          // Intentional fall-through to clear lower levels
          
        case 'group':
          // When group is cleared, clear all structure selections
          this.structureId = null;
          this.listStructureId = null;
          this.numberStructureId = null;
          this.structures = [];
          this.listStructures = [];
          this.numberStructures = [];
          // Intentional fall-through to clear lower levels
          
        case 'listStructure':
          // When list structure is cleared, clear nested value
          this.nestedValue = null;
          this.nestedValues = [];
          break;
          
        case 'numberStructure':
          // Currently no fields depend on numberStructure
          break;
          
        default:
          // Clear everything
          this.resetSelections();
          break;
      }
    },
    
    updatePagination(property, { page, itemsPerPage }) {
      this.pagination[property].currentPage = page;
      if (itemsPerPage) {
        this.pagination[property].itemsPerPage = itemsPerPage;
      }
    },
    
    handleNestedValuesSearchChange(searchTerm) {
      this.nestedValuesSearch = searchTerm;
      this.updatePagination('nestedValues', { page: 1, itemsPerPage: this.pagination.nestedValues.itemsPerPage });
    },
    
    handleStructuresSearchChange(searchTerm) {
      this.structuresSearch = searchTerm;
      this.updatePagination('structures', { page: 1, itemsPerPage: this.pagination.structures.itemsPerPage });
    },
    
    async getGroups() {
      this.loading.groups = true;
      const payload = {
        processIds: this.processId ? [this.processId] : [],
        belongsTo: "ALL"
      };

      try {
        const { data } = await ProcessService.fetchGroups(payload);

        this.groups = data.map(g => ({
          ...g,
          name: this.translate(g.languageKey)
        }));
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        this.loading.groups = false;
      }
    },
    
    async getListStructures() {
      this.loading.listStructures = true;
      const payload = {
        processIds: this.processId ? [this.processId] : [],
        groupIds: this.groupId ? [this.groupId] : [],
        structureTypes: this.listStructureTypes
      };

      try {
        const { data } = await ProcessService.fetchStructures(payload);

        this.listStructures = data.map(v => ({
          ...v,
          value: this.translate(v.name)
        }));

      } catch (error) {
        console.error("Error fetching list structures:", error);
      } finally {
        this.loading.listStructures = false;
      }
    },
    
    async getNumberStructures() {
      this.loading.numberStructures = true;
      const payload = {
        processIds: this.processId ? [this.processId] : [],
        groupIds: this.groupId ? [this.groupId] : [],
        structureTypes: this.numberStructureTypes
      };

      try {
        const { data } = await ProcessService.fetchStructures(payload);

        this.numberStructures = data.map(v => ({
          ...v,
          value: this.translate(v.name)
        }));

      } catch (error) {
        console.error("Error fetching number structures:", error);
      } finally {
        this.loading.numberStructures = false;
      }
    },

    async getStructures(structureTypes = ["NUMBER"]) {
      this.loading.structures = true;
      const payload = {
        processIds: this.processId ? [this.processId] : [],
        groupIds: this.groupId ? [this.groupId] : [],
        structureTypes: structureTypes
      };

      try {
        const { data } = await ProcessService.fetchStructures(payload);

        this.structures = data.map(v => ({
          ...v,
          value: this.translate(v.name)
        }));

        this.nestedValues = [];
      } catch (error) {
        console.error("Error fetching structures:", error);
      } finally {
        this.loading.structures = false;
      }
    },
    
    async getNestedValues(structureTypes) {
      if (!this.listStructureId) {
        this.nestedValues = [];
        return;
      }
      
      this.loading.nestedValues = true;
      const payload = {
        processIds: this.processId ? [this.processId] : [],
        groupIds: this.groupId ? [this.groupId] : [],
        structureIds: [this.listStructureId],
        structureTypes: structureTypes || this.listStructureTypes
      };

      try {
        const { data } = await ProcessService.fetchDataToSelect(payload);

        const newNestedValues = data.map(item => ({
          ...item,
          value: item.value || this.translate(item.name)
        }));

        this.nestedValues = [...newNestedValues];
      } catch (error) {
        console.error("Error fetching nested values:", error);
        this.nestedValues = [];
      } finally {
        this.loading.nestedValues = false;
      }
    },
    
    async getStructuresAndValues() {
      if (this.groupId) {
        // Get both list and number structures in parallel
        await Promise.all([
          this.getListStructures(),
          this.getNumberStructures()
        ]);
      }
    },
    
    resetSelections() {
      this.processId = null;
      this.groupId = null;
      this.structureId = null;
      this.listStructureId = null;
      this.numberStructureId = null;
      this.groups = [];
      this.structures = [];
      this.listStructures = [];
      this.numberStructures = [];
      this.nestedValues = [];
    }
  }
};