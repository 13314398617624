<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
    :advanced-options="{format: false, itemsLimit: false}"
  >
    <template v-slot:custom-fields>
      <v-container class="ma-0 pa-0">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              item-text="value"
              return-object
              outlined
              dense
              color="primary"
              item-color="primary"
              v-model="config.filter.layers"
              :items="layers"
              :disabled="layers.length === 0"
              multiple
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.layers") }}
                <span class="red--text"><strong>*</strong></span>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ $t("map.layers." + item).toUpperCase() | truncate(18) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ config.filter.layers.length - 1 }}
                            {{ $t('common.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 200px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="$t('map.layers.' + item).toUpperCase()"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-select
              :items="locationTypes"
              :no-data-text="$t('common.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.locationType')"
              v-model="config.filter.locationType"
              item-color="primary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="primary"
              @change="config.filter.classificationTypes = []"
            />
          </v-col>
          <classification-type-filed
            :config="config"
            :showField="() => true"
            :cols="12"
            filter-key="locationType"
          />
        </v-row>
      </v-container>
    </template>
  </FormComponent>
</template>

<script>
  import { mapGetters } from "vuex"
  import i18n from "@/plugins/i18n"
  import { FormWidgetMixin } from "@dashboard/mixins/form-widget.mixin";
  import { ClassificationTypeMixin } from "@/module/dashboard/mixins/classification-type.mixin"
  import FormComponent from "@dashboard/components/common/FormComponent.vue"
  import ColorPicker from "@dashboard/components/common/ColorPicker.vue"
  import IconPicker from "@dashboard/components/common/IconPicker.vue"
  import ClassificationTypeFiled from "@/module/dashboard/components/common/ClassificationTypeFiled.vue"
  import ReadMore from "@/components/ReadMoreComponent.vue"
  import { getByProperty } from "@/utils"

export default {
  name: 'MapDialog',
  components: { ClassificationTypeFiled, ReadMore, FormComponent, IconPicker, ColorPicker },
  mixins: [FormWidgetMixin, ClassificationTypeMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.MAP_LOCATIONS'),
        icon: 'mdi-chart-arc',
        color: '#232323',
        filter: {
          layers: [],
          locationType: null,
          classificationTypes: [],
        },
        subtype: 'MAP_LOCATIONS',
        type: 'CARD'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      selectedLayers: [],
      locationTypes: [
        {
          key: 'PRIMARY',
          name: i18n.t('locations.fields.name')
        },
        {
          key: 'SECONDARY',
          name: i18n.t('storage.fields.name')
        }
      ],
      config: {
        id: null,
        title: i18n.t('widgets.names.MAP_LOCATIONS'),
        icon: 'mdi-chart-arc',
        color: '#232323',
        filter: {
          layers: [],
          locationType: null,
          classificationTypes: [],
        },
        subtype: 'MAP_LOCATIONS',
        type: 'CARD'
      },
      layers: [
        'l_locations_clusters',
        'l_locations_clusters_count',
        'l_locations_up_zoom',
        'l_locations_down_zoom',
        'l_locations_clusters_points',
        'l_locations_clusters_points_icon',
        'l_locations_center_points',
        'l_locations_center_points_icon',
        'l_locations_names',
        'l_locations_fill_all',
        'l_locations_all',
        'l_storages_clusters',
        'l_storages_clusters_count',
        'l_storages_down_zoom',
        'l_storages_clusters_points',
        'l_storages_clusters_points_icon',
        'l_storages_center_points',
        'l_storages_center_points_icon',
        'l_storages_names',
        'l_storages_all'
      ],
      defaultConfig: null
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config }
    this.config = {
      ...this.item.props,
      subtype: "MAP_LOCATIONS",
      info: "mdi-MAP_LOCATIONS",
    };
  },
  computed: {
    ...mapGetters({
      classifications: "storage/GET_CLASSIFICATIONS",
    }),
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          layers: this.config.filter.layers,
          locationType: getByProperty(this.config.filter.locationType, "key"),
          classificationTypes: getByProperty(this.config.filter.classificationTypes, "id"),
        },
        initialized: true
      }

      this.$emit('update:config', config);
      this.$emit('update:layers');
      this.dialog = false;
    },
    async handleInitData() {
      this.config = { ...this.item.props };
      if (this.item.initialized) {
        this.config.filter.locationType = _.find(this.locationTypes, { key: getByProperty(this.config.filter.locationType, "key") });

        if (this.config.filter && this.config.filter.classificationTypes)
          this.config.filter.classificationTypes = this.config.filter.classificationTypes
            .map(id => this.findClassificationById(id))
            .filter(item => item !== null);
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = { ...e }
      this.saveConfig(e)
    }
  }
}
</script>

<style scoped>
  .v-card {
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #424242;
  }
</style>