import NumberFormatService from "@/services/NumberFormatService";
import { DataGroupingMixin } from "@/module/dashboard/mixins/data-grouping.mixin";
import { ChartBaseMixin } from "../../mixins/chart-base.mixin";

export const DotsMixin = {
  mixins: [DataGroupingMixin, ChartBaseMixin],
  data() {
    const baseConfig = this.getBaseChartConfig();
    return {
      chartOptions: {
        ...baseConfig,
        chart: {
          ...baseConfig.chart,
          type: 'area',
          stacked: false
        },
        theme: {
          monochrome: {
            color: `${this.item.props.color}`,
            enabled: true
          }
        },
        labels: this.item.props.values[0].xAxis,
        markers: {
          size: 5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        plotOptions: {
          area: {
            distributed: false
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint, this.format.region)
            }
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint, this.format.region)
            }
          }
        },
      },
      chartSeries: [{
        name: this.item.props.title,
        data: this.item.props.values[0].yAxis
      }],
      format: {
        afterPoint: null,
        region: null,
      },
    };
  },
  mounted() {
    this.ready = true;
    this.updateBaseChartLocale();
  },
  methods: {
    updateChart(e) {
      if (!e || !e.xAxis || !e.yAxis) {
        console.warn('Invalid data received in updateChart');
        return;
      }

      // Obtener el tipo de rango de la configuración del widget
      const rangeType = this.item?.props?.filter?.rangeDate || 'DAY';
      const rangeDateValue = this.item?.props?.filter?.rangeDateValue || 1;

      // Aplicar el agrupamiento de datos con el tipo de rango correcto
      const groupedData = this.groupDataByInterval(
        { keys: e.xAxis, values: e.yAxis },
        rangeType,
        rangeDateValue
      );

      // Calcular el ancho del contenedor
      const chartElement = this.$refs.renderValues.$el;
      const containerWidth = chartElement ? chartElement.offsetWidth : 800; // 800 es un valor por defecto

      // Calcular la longitud promedio de las etiquetas
      const avgLabelLength = groupedData.formattedKeys.reduce((acc, label) => acc + label.length, 0) / groupedData.formattedKeys.length;

      // Calcular el tickAmount óptimo
      const optimalTickAmount = this.calculateOptimalTickAmount(containerWidth, groupedData.keys.length, avgLabelLength);

      // Actualizar la configuración del eje X
      this.chartOptions.xaxis.tickAmount = optimalTickAmount;

      // Actualizar el gráfico con datos agrupados
      this.chartOptions.labels = groupedData.formattedKeys || groupedData.keys || [];
      this.chartSeries[0].data = groupedData.values || [];

      this.$set(this, 'format', e.format || { afterPoint: 2, region: 'en-us' });
      this.ready = true;
      this.$refs.renderValues?.refreshChart();
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBaseChartLocale();
      }
    }
  }
};
