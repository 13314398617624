import { render, staticRenderFns } from "./FactorSkeleton.vue?vue&type=template&id=0263f97d&scoped=true"
import script from "./FactorSkeleton.vue?vue&type=script&lang=js"
export * from "./FactorSkeleton.vue?vue&type=script&lang=js"
import style0 from "./FactorSkeleton.vue?vue&type=style&index=0&id=0263f97d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0263f97d",
  null
  
)

export default component.exports