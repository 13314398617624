import NumberFormatService from "@/services/NumberFormatService";
import { DataGroupingMixin } from "@/module/dashboard/mixins/data-grouping.mixin";
import { ChartBaseMixin } from "../../mixins/chart-base.mixin";

export const ColumnMixin = {
  mixins: [DataGroupingMixin, ChartBaseMixin],
  data() {
    const baseConfig = this.getBaseChartConfig();
    return {
      chartOptions: {
        ...baseConfig,
        chart: {
          ...baseConfig.chart,
          type: 'bar',
          toolbar: {
            ...baseConfig.chart.toolbar,
            export: {
              ...baseConfig.chart.toolbar.export,
              csv: {
                ...baseConfig.chart.toolbar.export.csv,
                headerValue: this.item?.props?.title || 'Valores'
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint || 2, this.format.region || 'en-us')
            }
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            distributed: true,
            columnWidth: '40%'
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: this.item.props.color,
          }
        },
        grid: {
          show: false
        },
        legend: {
          show: false
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint || 2, this.format.region || 'en-us')
            }
          }
        },
      },
      chartSeries: [{
        name: this.item?.props?.title || '',
        data: this.item.props.values.yAxis || []
      }],
      format: {
        afterPoint: null,
        region: null,
      },
    };
  },
  mounted() {
    this.ready = true;
    this.updateBaseChartLocale();
  },
  methods: {
    updateChart(e) {
      if (!e || !e.xAxis || !e.yAxis) {
        console.warn('Invalid data received in updateChart');
        return;
      }

      const rangeType = this.item?.props?.filter?.rangeDate || 'DAY';
      const rangeDateValue = this.item?.props?.filter?.rangeDateValue || 1;

      const groupedData = this.groupDataByInterval(
        { keys: e.xAxis, values: e.yAxis },
        rangeType,
        rangeDateValue
      );

      // Calcular el número óptimo de ticks basado en el tipo de rango
      const tickAmount = this.getTickAmount(groupedData.keys.length, rangeType);
      this.chartOptions.xaxis.tickAmount = tickAmount;

      // Calcular el ancho del contenedor
      const chartElement = this.$refs.renderValues.$el;
      const containerWidth = chartElement ? chartElement.offsetWidth : 800;

      // Calcular la longitud promedio de las etiquetas
      const avgLabelLength = groupedData.formattedKeys.reduce((acc, label) => acc + label.length, 0) / groupedData.formattedKeys.length;

      // Calcular el tickAmount óptimo
      const optimalTickAmount = this.calculateOptimalTickAmount(containerWidth, groupedData.keys.length, avgLabelLength);

      // Actualizar la configuración del eje X
      this.chartOptions.xaxis.tickAmount = optimalTickAmount;

      // Actualizar las categorías del eje X con las fechas formateadas
      this.chartOptions.xaxis.categories = groupedData.formattedKeys || groupedData.keys || [];
      
      // Actualizar el nombre de la serie al actualizar datos
      this.chartSeries[0].name = this.item?.props?.title || '';
      this.chartSeries[0].data = groupedData.values || [];

      this.$set(this, 'format', e.format || { afterPoint: 2, region: 'pt-br' });
      this.ready = true;
      this.$refs.renderValues?.refreshChart();
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBaseChartLocale();
      }
    }
  }
};
