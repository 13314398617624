<template>
  <v-dialog v-model="localDialog" persistent v-bind="propsDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="loadInitData()" small icon v-bind="attrs" v-on="on" v-show="isEditingEnabled">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline d-flex justify-space-between align-center">
        <slot name="title">
          <h3>{{$t("widgets.dialogs.title")}}</h3>
        </slot>
        <icon-picker :color="localData.color" :current="localData.icon.slice(4, localData.icon.length)" v-model="localData.icon" />
        <ChipWidgetSubtype :name="$t('widgets.names.'+ localData.subtype)" :icon="localData.icon" :info="$t('widgets.descriptions.'+ localData.subtype)" />
      </v-card-title>
      <v-card-text  v-if="localDialog">
        <v-form ref="form" v-model="localValid">
          <v-list class="ma-0 pa-0 custom-list">
            <v-list-group
              :value="true"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon small left> mdi-more </v-icon>
                    {{ $t('widgets.dialogs.sections.options') }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="ma-0 pa-0">
                <v-list-item-content class="d-block">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-text-field outlined dense color="primary" v-model="localData.title" :label="$t('widgets.dialogs.fields.title')" :rules="requireRules">
                        <template v-slot:label>
                          {{$t("widgets.dialogs.fields.title")}}
                          <strong class="red--text">*</strong>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Slots para campos específicos -->
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                      <slot name="custom-fields"></slot>
                    </v-col>
                  </v-row>
                  <color-picker :current="localData.color" v-model="localData.color" />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list class="ma-0 pa-0 custom-list" v-if="requiredAdvancedOption">
            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon small left> mdi-more </v-icon>
                    {{ $t('widgets.dialogs.sections.advanced') }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="ma-0 pa-0">
                <v-list-item-content>
                  <v-subheader class="pl-0" v-if="advancedOptions.format">{{$t("widgets.dialogs.fields.advancedOptions.formatTitle")}}</v-subheader>
                  <v-row v-if="advancedOptions.format">
                    <v-col cols="8">
                      <v-select
                        :items="regions"
                        clearable
                        outlined
                        dense
                        color="primary"
                        v-model="localData.format.region"
                        :label="$t('widgets.dialogs.format.region')"
                        item-text="name"
                        item-value="value"
                        item-color="primary"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        clearable
                        outlined
                        dense
                        color="primary"
                        v-model.number="localData.format.afterPoint"
                        :min="0"
                        :max="3"
                        :label="$t('widgets.dialogs.format.afterPoint')"
                        :rules="requireAndMinMax(0, 3)"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader class="pl-0" v-if="advancedOptions.itemsLimit">{{$t("widgets.dialogs.fields.advancedOptions.itemsLimitTitle")}}</v-subheader>
                  <v-row v-if="advancedOptions.itemsLimit">
                    <v-col cols="12">
                      <v-text-field
                        color="secondary"
                        type="text"
                        outlined
                        dense
                        v-model='localData.itemsLimit'
                        :label="$t('widgets.dialogs.itemsLimit')"
                        :rules="[...onlyAllowInteger(),...minMax(1, 100)]"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small text color="neuter" @click="closeDialog">{{$t('common.buttons.cancel')}}</v-btn>
        <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="saveConfig" :disabled="!(localValid && extraValid)">{{$t('common.buttons.save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import _ from 'lodash';

export default {
  name: 'FormComponent',
  components: { ChipWidgetSubtype, IconPicker, ColorPicker },
  mixins: [FormWidgetMixin],
  props: {
    dialog: Boolean,
    propsDialog: {
      type: Object,
      default: () => ({
        maxWidth: '500px',
      })
    },
    config: Object,
    valid: {
      type: Boolean,
      default: true
    },
    extraValid: {
      type: Boolean,
      default: true
    },
    advancedOptions: {
      type: Object,
      default() {
        return {
          format: true,
          itemsLimit: false
        }
      }
    },
  },
  data() {
    return {
      localDialog: this.dialog,
      localValid: this.valid,
      localData: {...this.config},
      storageId: null
    };
  },
  created() {
    this.storageId = `temp:card-data:${this.config.id}`;
  },
  mounted() {
    if (this.$refs.form) {
      this.localValid = this.$refs.form.validate();
    }
  },

  computed: {
    layout: {
      get() {
        return this.$store.getters['dashboard/LAYOUT'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_LAYOUT', val);
      }
    },
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    },
    requiredAdvancedOption() {
      return _.some(this.advancedOptions, value => value === true);
    }
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
    },
    valid(newVal) {
      this.localValid = newVal;
    },
    localDialog(newVal) {
      this.$emit('update:dialog', newVal);
    },
    localValid(newVal) {
      this.$emit('update:valid', newVal);
    },
  },
  methods: {
    loadInitData() {
      localStorage.setItem(this.storageId, JSON.stringify(this.config));
      this.$emit('load:init-data');
    },
    closeDialog() {
      const data = JSON.parse(localStorage.getItem(this.storageId));
      if (data) {
        this.$emit('clear:form', JSON.parse(localStorage.getItem(this.storageId)));
        localStorage.removeItem(this.storageId);
      } else {
        const widget = _.findIndex(this.layout.widgets, {i: this.config.id})
        this.layout.widgets.splice(widget, 1);
      }

      this.$emit('update:dialog', false);
    },
    saveConfig() {
      if (this.$refs.form.validate()) {
        const data = {
          ...this.localData,
          color: this.localData.color ? this.localData.color.slice(0, 7) : '#202020',
          itemsLimit: this.localData.itemsLimit && this.localData.itemsLimit > 0 ? this.localData.itemsLimit : null
        };
        console.log(data)
        this.$emit('save:form', data);
        localStorage.removeItem(this.storageId);

        this.localValid = true;
      } else {
        this.localValid = false;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #424242;
}

.custom-list:deep(div.v-list-item) {
  padding: 0 !important;
  margin: 0 !important;
}

:deep(div.col-12)  {
  padding-bottom: 0 !important;
}
:deep(div.col-6) {
  padding-bottom: 0 !important;
}
</style>
