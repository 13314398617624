<template>
  <v-row>
    <v-col cols="6">
      <v-select
        :items="rangeDates"
        :no-data-text="$t('common.fields.noDataAvailable')"
        :clearable="true"
        :label="$t('widgets.dialogs.filters.rangeDate')"
        v-model="selectedRange"
        item-color="primary"
        item-text="name"
        item-value="key"
        dense
        flat
        outlined
        color="primary"
        :rules="selectRule"
        @change="handleRangeChange"
      >
        <template v-slot:label>
          {{ $t('widgets.dialogs.filters.rangeDate') }}
          <strong class="red--text">*</strong>
        </template>
      </v-select>
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="rangeDateValue"
        type="number"
        :label="$t('widgets.dialogs.filters.rangeDateValue')"
        :rules="[
          v => !!v || $t('common.rules.required'),
          v => (v > 0 && v <= maxValue) || $t('common.rules.maxValue', { limit: maxValue })
        ]"
        :hint="getHintText"
        persistent-hint
        dense
        outlined
        @input="handlerangeDateValueChange"
        @keypress="validateNumber"
        min="1"
        :max="maxValue"
      >
        <template v-slot:label>
          {{ getrangeDateValueLabel }}
          <strong class="red--text">*</strong>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { DateRangeMixin } from "@/module/dashboard/mixins/date-range.mixin";

export default {
  name: 'DateRangeSelector',
  mixins: [DateRangeMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        rangeDate: null,
        rangeDateValue: null
      })
    },
    selectRule: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedRange: null,
      rangeDateValue: null,
      maxValue: 90
    }
  },
  computed: {
    getrangeDateValueLabel() {
      const range = this.rangeDates.find(r => r.key === this.selectedRange);
      if (!range) return this.$t('widgets.dialogs.filters.rangeDateValue');
      return this.$t(`widgets.dialogs.filters.${range.unit}Value`);
    },
    getHintText() {
      const range = this.rangeDates.find(r => r.key === this.selectedRange);
      if (!range) return '';
      
      return this.$t('widgets.dialogs.hints.range', { 
        unit: this.$t(`common.units.${range.unit}`),
        max: this.maxValue 
      });
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          // Si viene como objeto con key, usar el key
          this.selectedRange = newVal.rangeDate?.key || newVal.rangeDate;
          this.maxValue = this.getMaxValueForRange(this.selectedRange);
          // Asegurar que rangeDateValue sea un número
          this.rangeDateValue = parseInt(newVal.rangeDateValue) || 0;
          
          // Validar el valor contra el máximo
          if (this.rangeDateValue > this.maxValue) {
            this.rangeDateValue = this.maxValue;
            this.emitChange();
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleRangeChange(value) {
      if (!value) return;
      
      const oldRange = this.selectedRange;
      this.selectedRange = value;
      this.maxValue = this.getMaxValueForRange(value);

      if (this.rangeDateValue) {
        // Convertir y validar el valor
        const convertedValue = this.convertValueBetweenRanges(
          this.rangeDateValue,
          oldRange,
          value
        );
        this.rangeDateValue = Math.min(convertedValue, this.maxValue);
      }

      this.emitChange();
    },
    validateNumber(event) {
      // Allow only numbers and prevent invalid inputs
      const char = String.fromCharCode(event.keyCode);
      const value = event.target.value + char;
      
      // Prevent non-numeric characters
      if (!/^\d*$/.test(char)) {
        event.preventDefault();
        return;
      }
      
      // Prevent values outside the valid range
      if (parseInt(value) > this.maxValue) {
        event.preventDefault();
        return;
      }
      
      // Prevent zero as first digit
      if (event.target.value.length === 0 && char === '0') {
        event.preventDefault();
        return;
      }
    },
    handlerangeDateValueChange(value) {
      if (!value) {
        this.rangeDateValue = null;
        return;
      }
      
      let numValue = parseInt(value);
      
      // Ensure the value is within bounds
      if (isNaN(numValue)) {
        numValue = null;
      } else {
        if (numValue < 1) numValue = 1;
        if (numValue > this.maxValue) numValue = this.maxValue;
      }
      
      this.rangeDateValue = numValue;
      this.emitChange();
    },
    emitChange() {
      if (!this.selectedRange || !this.rangeDateValue) return;
      
      this.$emit('input', {
        rangeDate: this.selectedRange,
        rangeDateValue: parseInt(this.rangeDateValue) || null
      });
    }
  }
}
</script>
