<template>
  <v-dialog v-model="showDialog" max-width="1024" :persistent="isOwner">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="show__btn" v-bind="attrs" small text @click.stop="openDialog">
        <v-icon color="black" left small>mdi-share-variant-outline</v-icon>
        {{ $t('dashboard.buttons.share') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline secondary t-bw-secondary--text">{{ $t('dashboard.dialogs.share.title') }}</v-card-title>
      <v-card-text class="pa-6">
        <v-alert border="left" class="mt-2" color="orange" dense icon="mdi-alert-outline" text type="warning">
          {{ $t('dashboard.dialogs.share.alert') }}
        </v-alert>
        <v-overlay v-if="!isOwner" absolute color="white" opacity=".9">
          <v-chip class="overlay-chip" color="grey lighten-5" text-color="grey">
            {{ $t('dashboard.dialogs.share.noPermission') }}
          </v-chip>
        </v-overlay>
        <div v-if="isOwner">
          <v-row >
            <v-col cols="12" md="7">
              <v-autocomplete
                :disabled="loadingTable"
                color="primary"
                item-color="primary"
                return-object
                v-model="selectedUsers"
                :items="filteredUsers"
                :label="$t('dashboard.dialogs.share.fields.users')"
                item-text="name"
                item-value="id"
                multiple
                outlined
                dense
                small-chips
                clearable
                deletable-chips
                :chips-transform="transformChips"
              >
                <template
                  v-slot:selection="{ item, index, attrs, select, selected }"
                >
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedUsers.length - 1 }}
                      {{ $t("common.titles.others") }})
                    </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                :disabled="loadingTable"
                color="primary"
                item-color="primary"
                v-model="selectedPermissions"
                :items="selectablePermissions"
                :label="$t('dashboard.dialogs.share.fields.permissions')"
                item-text="name"
                item-value="key"
                multiple
                outlined
                dense
                small-chips
                clearable
              >
                <template
                  v-slot:selection="{ item, index, attrs, select, selected }"
                >
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedPermissions.length - 1 }}
                      {{ $t("common.titles.others") }})
                    </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="pt-4">
              <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="addPermissionsToSelectedUsers" :disabled="!(selectedUsers.length > 0 && selectedPermissions.length > 0) || loadingTable">
                <v-icon left>mdi-check</v-icon>
                {{ $t('dashboard.buttons.apply') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-data-table :headers="tableHeaders"
                        :items="userPermissions"
                        :loading="loadingTable"
                        :footer-props="{
                          disableItemsPerPage: true,
                          itemsPerPageText: $t('common.table.itemsPerPageText'),
                          pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                        }"
                        :items-per-page="5"
                        fixed-header>
            <template v-slot:item.permissions="{ item }">
              <v-autocomplete
                style="width: 250px"
                color="primary"
                item-color="primary"
                v-model="item.permissions"
                :items="selectablePermissions"
                v-if="!item.isOwner"
                item-text="name"
                item-value="key"
                return-object
                multiple
                dense
                flat
                outlined
                clearable
                hide-details
                small-chips
              >
                <template
                  v-slot:selection="{ item:data, index, attrs, select, selected }"
                >
                  <v-chip v-if="index === 0" small>
                    <span>{{ data.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                      (+{{ item.permissions.length - 1 }}
                      {{ $t("common.titles.others") }})
                    </span>
                </template>
              </v-autocomplete>
              <span v-else>
                <v-chip small v-for="permission in item.permissions" class="mx-1">{{ getPermission(permission) }}</v-chip>
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn small
                     icon
                     @click="removeUser(item)"
                     v-if="!item.isOwner"
              >
                <v-icon color="red">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small text color="neuter" @click="closeDialog" :disabled="loading && loadingTable">{{ $t('dashboard.buttons.close') }}</v-btn>
        <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="shareDashboard" :disabled="(userPermissions.length === 0 && loadingTable)" :loading="loading">
          {{ $t('dashboard.buttons.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import i18n from "@/plugins/i18n"
  import { getByProperty } from "@/utils"

  export default {
    name: 'ShareDialog',
    props: {
      layout: Object
    },
    data() {
      return {
        showDialog: false,
        selectedUsers: [],
        selectedPermissions: [],
        loading: false,
        loadingTable: false,
        profile: JSON.parse(localStorage.getItem('profile')),
        filteredUsers: []
      };
    },
    computed: {
      ...mapGetters({
        users: "authority/authoritiesForLocations"
      }),
      selectablePermissions() {
        return this.permissions.filter((p) => p.key !== 'WRITE')
      },
      permissions: () => [
        {
          name: i18n.t('dashboard.dialogs.share.permissions.READ'),
          key: 'READ'
        },
        {
          name: i18n.t('dashboard.dialogs.share.permissions.DELETE'),
          key: 'DELETE'
        },
        {
          name: i18n.t('dashboard.dialogs.share.permissions.WRITE'),
          key: 'WRITE'
        },
        {
          name: i18n.t('dashboard.dialogs.share.permissions.COPY'),
          key: 'COPY'
        }
      ],
      userPermissions: {
        get() {
          return this.$store.getters["dashboard/GET_USER_PERMISSIONS"];
        },
        set(val) {
          return this.$store.commit("dashboard/SET_USER_PERMISSIONS", val);
        }
      },
      isOwner() {
        return this.layout.user.isOwner;
      },
      tableHeaders() {
        return [
          { text: this.$t('dashboard.dialogs.share.fields.users'), value: 'name' },
          { text: this.$t('dashboard.dialogs.share.fields.permissions'), value: 'permissions' },
          { text: this.$t('common.fields.action'), value: 'actions', sortable: false }
        ];
      }
    },
    methods: {
      ...mapActions({
        fetchListAuthority: 'authority/fetchListAuthority',
        shareDashboardAction: 'dashboard/addUserPermissions',
        getUserPermissions: 'dashboard/getUserPermissions'
      }),
      getPermission(key) {
        const p = this.permissions.find(permission => permission.key === key);
        return p ? p.name : '-';
      },
      async openDialog() {
        this.$emit('close');
        this.showDialog = true;
        this.loadingTable = true;

        await this.fetchListAuthority([this.profile, this.$toast]);
        await this.getUserPermissions(this.layout.id).finally(() => {
          this.loadingTable = false;
        });

        this.filteredUsers = this.users.filter(user => user.id !== this.profile.id);

        this.userPermissions = this.userPermissions.map(user => {
          const foundUser = this.users.find(u => u.id === user.personInChargeId);
          if (foundUser) {
            this.filteredUsers = this.filteredUsers.filter(u => u.id !== foundUser.id);
          }
          return {
            id: user.personInChargeId,
            name: foundUser ? foundUser.name : '',
            permissions: user.permissions,
            isOwner: this.profile.id === foundUser.id ? this.layout.user.isOwner : false,
          };
        });
      },
      addPermissionsToSelectedUsers() {
        this.selectedUsers.forEach(user => {
          if (!this.userPermissions.find(u => u.id === user.id)) {
            this.userPermissions.push({
              id: user.id,
              name: user.name,
              permissions: [...this.selectedPermissions]
            });
            this.filteredUsers = this.filteredUsers.filter(u => u.id !== user.id);
          }
        });
        this.selectedUsers = [];
        this.selectedPermissions = [];
      },
      removeUser(user) {
        this.userPermissions = this.userPermissions.filter(u => u.id !== user.id);
        this.filteredUsers.push({
          id: user.id,
          name: user.name
        });
      },
      async shareDashboard() {
        if (this.userPermissions.length > 0) {
          this.loading = true;
          const payload = this.userPermissions.map(user => ({
            personInChargeId: user.id,
            permissions: user.permissions.map((p) => getByProperty(p, 'key'))
          }));
          console.log({
            dashboardId: this.layout.id,
            users: payload
          })

          await this.shareDashboardAction({
            dashboardId: this.layout.id,
            users: payload
          });
          this.loading = false;
          this.closeDialog();
        }
      },
      closeDialog() {
        this.selectedUsers = [];
        this.selectedPermissions = [];
        this.userPermissions = [];
        this.showDialog = false;
      },
      transformChips(users) {
        if (users.length > 1) {
          return [`${users[0].name} (+${users.length - 1})`];
        }
        return users.map(user => user.name);
      }
    }
  };
</script>

<style scoped>
  .overlay-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2em .2em 0 0 !important;
  }

  .show__btn.v-btn {
    width: 100%;
  }

  >>> span.v-btn__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
</style>
