import i18n from "@/plugins/i18n";

export const DateRangeMixin = {
  data() {
    return {
      rangeDates: [
        {
          key: 'DAY',
          name: i18n.t('widgets.dialogs.rangeDate.day'),
          maxValue: 90,  // Ajustado para mejor visualización
          unit: 'days'
        },
        {
          key: 'WEEK',
          name: i18n.t('widgets.dialogs.rangeDate.week'),
          maxValue: 52,  // 1 año en semanas
          unit: 'weeks'
        },
        {
          key: 'MONTH',
          name: i18n.t('widgets.dialogs.rangeDate.month'),
          maxValue: 24,  // 2 años en meses
          unit: 'months'
        },
        {
          key: 'YEAR',
          name: i18n.t('widgets.dialogs.rangeDate.year'),
          maxValue: 5,   // 5 años
          unit: 'years'
        }
      ]
    }
  },
  methods: {
    getMaxValueForRange(rangeType) {
      const range = this.rangeDates.find(r => r.key === rangeType);
      return range ? range.maxValue : 0;
    },
    convertValueBetweenRanges(value, fromRange, toRange) {
      if (!value || !fromRange || !toRange) return value;
      
      let days = this.convertToDays(value, fromRange);
      
      switch(toRange) {
        case 'YEAR':
          return Math.min(Math.round(days / 365), this.getMaxValueForRange('YEAR'));
        case 'MONTH':
          return Math.min(Math.round(days / 30), this.getMaxValueForRange('MONTH'));
        case 'WEEK':
          return Math.min(Math.round(days / 7), this.getMaxValueForRange('WEEK'));
        case 'DAY':
          return Math.min(days, this.getMaxValueForRange('DAY'));
        default:
          return value;
      }
    },
    convertToDays(value, rangeType) {
      if (!value || !rangeType) return 0;
      
      switch(rangeType) {
        case 'YEAR':
          return value * 365;
        case 'MONTH':
          return value * 30;
        case 'WEEK':
          return value * 7;
        case 'DAY':
        default:
          return value;
      }
    }
  }
}
