<template>
  <div class="error-boundary">
    <slot v-if="!error"></slot>
    <div v-else class="error-container">
      <slot name="error" :error="error">
        <div class="widget-error">
          <div class="error-icon">
            <v-icon color="error">mdi-alert-circle</v-icon>
          </div>
          <div class="error-content">
            <div class="error-title">{{ $t('dashboard.errors.widgetFailed') }}</div>
            <div class="error-message">{{ getShortErrorMessage(error.message) }}</div>
            <div class="error-actions">
              <v-btn x-small text color="primary" @click="resetError">
                <v-icon x-small left>mdi-refresh</v-icon>
                {{ $t('common.buttons.retry') }}
              </v-btn>
              <v-btn x-small text color="grey darken-1" @click="showDetails = !showDetails">
                {{ showDetails ? $t('common.buttons.hideDetails') : $t('common.buttons.showDetails') }}
              </v-btn>
            </div>
            <transition name="fade">
              <div v-if="showDetails" class="error-details">
                <div class="error-details-title">Error Details:</div>
                <div class="error-details-info">
                  <span class="detail-label">Component:</span> {{ error.component }}
                </div>
                <div class="error-details-info" v-if="error.info">
                  <span class="detail-label">Location:</span> {{ error.info }}
                </div>
                <pre class="error-stack">{{ error.message }}</pre>
              </div>
            </transition>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorBoundary',
  props: {
    componentName: {
      type: String,
      default: 'component'
    },
    excludeComponents: {
      type: Array,
      default: () => []
    },
    widgetType: {
      type: String,
      default: null
    },
    widgetSubtype: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      error: null,
      showDetails: false
    };
  },
  methods: {
    resetError() {
      this.error = null;
    },
    getShortErrorMessage(message) {
      // Return first line or first 50 characters with ellipsis
      if (!message) return 'Unknown error';
      
      const firstLine = message.split('\n')[0];
      return firstLine.length > 50 ? firstLine.substring(0, 50) + '...' : firstLine;
    },
    shouldExclude() {
      // Check if current widget type and subtype combination should be excluded
      if (this.widgetType && this.excludeComponents.includes(this.widgetType)) {
        return true;
      }
      
      if (this.widgetType && this.widgetSubtype) {
        const fullType = `${this.widgetType}-${this.widgetSubtype}`;
        return this.excludeComponents.includes(fullType);
      }
      
      return false;
    }
  },
  errorCaptured(err, vm, info) {
    // Check if this widget should be excluded from error handling
    if (this.shouldExclude()) {
      console.warn(`Error in excluded component ${this.widgetType}-${this.widgetSubtype} was propagated:`, err);
      return true; // Let the error propagate up
    }
    
    const componentName = vm.$options.name || this.componentName;
    
    this.error = {
      message: err.message || 'Unknown error',
      stack: err.stack,
      component: componentName,
      info
    };
    
    // Log error with component details
    console.error(`Error in ${componentName} (${this.widgetType}-${this.widgetSubtype}):`, err);
    
    // Prevent error from propagating further
    return false;
  }
};
</script>

<style scoped>
.error-boundary {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.error-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
}

.widget-error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 235, 235, 0.95);
  border: 1px solid rgba(207, 34, 46, 0.2);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.error-icon {
  text-align: center;
  padding-top: 8px;
}

.error-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow: auto;
}

.error-title {
  font-weight: 600;
  color: #cf222e;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: center;
}

.error-message {
  font-size: 12px;
  color: #24292f;
  margin-bottom: 8px;
  text-align: center;
  overflow-wrap: break-word;
}

.error-actions {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.error-details {
  margin-top: 4px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  font-size: 12px;
}

.error-details-title {
  font-weight: 600;
  margin-bottom: 4px;
  color: #24292f;
}

.error-details-info {
  margin-bottom: 4px;
  color: #57606a;
}

.detail-label {
  font-weight: 600;
}

.error-stack {
  margin: 4px 0 0;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  font-family: monospace;
  font-size: 11px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #57606a;
  max-height: 100px;
  overflow: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
