<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >
  </FormComponent>
</template>

<script>
import FormComponent from "@dashboard/components/common/FormComponent.vue";
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";
import {GlobalWidgetMixin} from "@/module/dashboard/mixins/global-widget.mixin";
import {CommonWidgetMixin} from "@/module/dashboard/mixins/common-widget.mixin";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import i18n from "@/plugins/i18n";

export default {
  name: 'QrVisitCitiesDialog',
  components: {FormComponent, IconPicker, ColorPicker},
  mixins: [GlobalWidgetMixin, CommonWidgetMixin, FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.CITY_QR_VISIT'),
        icon: 'mdi-grid',
        color: '#232323',
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'CITY_QR_VISIT',
        type: 'TABLE'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.CITY_QR_VISIT'),
        icon: 'mdi-grid',
        color: '#232323',
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'CITY_QR_VISIT',
        type: 'TABLE'
      },
      defaultConfig: null
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config }
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "CITY_QR_VISIT",
      info: "CITY_QR_VISIT",
    };
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        initialized: true
      }

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      this.config = { ...this.item.props };
      if (!this.item.initialized) {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
};
</script>

<style scoped>
</style>
